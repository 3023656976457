import React, {useState} from 'react';
import firebase from '../../config/fbConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const db = firebase.firestore();

function Schoolgids (props) {
    const { extra } = props;
    const [downloadUrl, setDownloadUrl] = useState('');
    
    const fetchDownloadUrl = async () => {
        const downloadUrl = await db.collection("fileLinks").doc("schoolgids").get();
        setDownloadUrl(downloadUrl.data().url);
    }
    fetchDownloadUrl();

    return (
        <div className={extra}>
            <a href={downloadUrl ? downloadUrl : "/"} target="_blank" rel="noreferrer" className="purple darken-2 btn-large">Bekijk Schoolgids <FontAwesomeIcon icon={faExternalLinkAlt}/></a>                        
        </div>
    ) 
}

export default Schoolgids;