import React from 'react';
import TeamMember from './TeamMember';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

const TeamList = (props) => {
    const { teamMembers } = props;
    return (
        <div>
            { teamMembers && teamMembers.map(teamMember => {
                if(teamMember.bestuur === false){
                return (
                    <div className="col s12 l6" key={teamMember.id}>
                        <TeamMember teamMember={teamMember}/> 
                    </div>                  
                )} else { return null }
            })}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        teamMembers: state.firestore.ordered.teamMembers
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'teamMembers', orderBy: ['functie'] }
    ])
)(TeamList);