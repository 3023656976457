import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/layout/Header';
import Routes from './components/Routes';
import FooterComp from './components/layout/FooterComp';

class App extends Component {
  render(){
    return (
      <BrowserRouter>
        <div className="App">
          <Header />
          <div className="main"><Routes /></div>          
          <FooterComp />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
