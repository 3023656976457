import React, {useState} from 'react';
import firebase from 'firebase/app';

function EditStaticTexts(){
    const [settings, setSettings] = useState(null);

    const fetchSettings = async () => {
        const settingsDoc = await firebase.firestore().collection('settings').doc('teksten').get();
        setSettings(settingsDoc.data());
    }
    if(!settings){
        fetchSettings();
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); 

        await firebase.firestore().collection('settings').doc('teksten').update(settings); 
        window.location.reload();
    }

    return (
        <div>
            {!settings ? "Teksten laden..." :
            <form onSubmit={handleSubmit} id="memberForm" style={{marginTop:"0"}}>
                <div className="input-field">
                    <label htmlFor='welkomTekst'className="grey-text text-darken-3 active">Welkom Tekst</label>
                    <textarea id='welkomTekst' className="materialize-textarea" onChange={e => settings.welkom = e.target.value} defaultValue={settings.welkom} />
                </div>
                <br/>
                <div className="input-field">
                    <label htmlFor='welkomKnop'className="grey-text text-darken-3 active">Welkom Knop</label>
                    <textarea id='welkomKnop' className="materialize-textarea" onChange={e => settings.welkomKnop = e.target.value} defaultValue={settings.welkomKnop} />
                </div>
                <br/>
                <div className="input-field">
                    <label htmlFor='welkomPopup'className="grey-text text-darken-3 active">Welkom Popup</label>
                    <textarea id='welkomPopup' className="materialize-textarea" onChange={e => settings.welkomPopup = e.target.value} defaultValue={settings.welkomPopup} />
                </div>
                <br/>
                <div className="input-field">
                    <label htmlFor='missieTekst'className="grey-text text-darken-3 active">Missie Tekst</label>
                    <textarea id='missieTekst' className="materialize-textarea" onChange={e => settings.missie = e.target.value} defaultValue={settings.missie} />
                </div>
                <br/>
                <div className="input-field">
                    <label htmlFor='visieTekst'className="grey-text text-darken-3 active">Visie Tekst</label>
                    <textarea id='visieTekst' className="materialize-textarea" onChange={e => settings.visie = e.target.value} defaultValue={settings.visie} />
                </div>
                <br/>
                <div className="input-field">
                    <label htmlFor='nobTekst'className="grey-text text-darken-3 active">NOB Tekst</label>
                    <textarea id='nobTekst' className="materialize-textarea" onChange={e => settings.nob = e.target.value} defaultValue={settings.nob} />
                </div>
                <br/>
                <div className="input-field">
                    <label htmlFor='bestuurTekst'className="grey-text text-darken-3 active">Bestuur Tekst</label>
                    <textarea id='bestuurTekst' className="materialize-textarea" onChange={e => settings.bestuur = e.target.value} defaultValue={settings.bestuur} />
                </div>
                <br/>
                <div className="input-field">
                    <button className="btn blue darken-4 z-depth-0" id="opslaanButton">Opslaan</button>
                </div>
            </form>}
        </div>
    )
}

export default EditStaticTexts;