import React from 'react';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Button, Modal } from 'react-materialize';
import M from "materialize-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';

const db = firebase.firestore();

const EditTeamMember = ({teammember}) => {
    const editTeamMember = {
        id: teammember.id,
        naamEdit: teammember.naam,
        fotoEdit: teammember.foto,
        fotoNameEdit: teammember.fotoName,
        functieEdit: teammember.functie,
        overEdit: teammember.over,
        bestuurEdit: teammember.bestuur,
        imageFile: null
    }
    
    const handleFileChange = async (e) => {
        if(e.target.files[0]){
            const image = e.target.files[0];
            editTeamMember.imageFile = image;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        editTeamMemberFunc(editTeamMember);
    }

    const editTeamMemberFunc = (editedTeamMember) => {
        var saveButton = document.getElementById(`saveButton-${editedTeamMember.id}`);
        saveButton.value = "Opslaan...";
        saveButton.classList.add("disabled");
        var modalElem = document.getElementById(`bewerk-modal-${editedTeamMember.id}`)
        var instance = M.Modal.getInstance(modalElem);

        if(editedTeamMember.imageFile){
            const uploadTask = firebase.storage().ref(`team/${editedTeamMember.imageFile.name}`).put(editedTeamMember.imageFile);
            uploadTask.on('state_changed', 
            (snapshot) => {}, 
            (error) => { console.log(error); }, 
            () => {
                firebase.storage().ref('team').child(editedTeamMember.imageFile.name).getDownloadURL().then((fotoUrl) => {
                    if(editedTeamMember.fotoNameEdit){
                        const memberFotoRef = firebase.storage().ref().child(`team/${editedTeamMember.fotoNameEdit}`);
                        memberFotoRef.delete().catch(function(error) {
                            console.log(error);
                        });
                    }
                    editedTeamMember.fotoEdit = fotoUrl;
                    editedTeamMember.fotoNameEdit = editedTeamMember.imageFile.name;
                    db.collection("teamMembers").doc(editedTeamMember.id).update({
                        naam: editedTeamMember.naamEdit,
                        foto: editedTeamMember.fotoEdit,
                        fotoName: editedTeamMember.fotoNameEdit,
                        functie: editedTeamMember.functieEdit,
                        over: editedTeamMember.overEdit,
                        bestuur: editedTeamMember.bestuurEdit
                    }).then(() => {
                        saveButton.value = "Verandering opslaan";
                        saveButton.classList.remove("disabled");
                        instance.close();
                        resetForm();
                    }).catch((err) => {
                        console.log(err.message);
                    });                
                });
            });
        } else {
            db.collection("teamMembers").doc(editedTeamMember.id).update({
                naam: editedTeamMember.naamEdit,
                functie: editedTeamMember.functieEdit,
                over: editedTeamMember.overEdit,
                bestuur: editedTeamMember.bestuurEdit
            }).then(() => {
                saveButton.value = "Verandering opslaan";
                saveButton.classList.remove("disabled");
                instance.close();
                resetForm();
            }).catch((err) => {
                console.log(err.message);
            });
        }        
    }

    const resetForm = () => {
        document.getElementById(`editTeamMemberForm-${editTeamMember.id}`).reset();
        document.getElementById(`naamEditLabel-${editTeamMember.id}`).classList.add("active");
        document.getElementById(`functieEditLabel-${editTeamMember.id}`).classList.add("active");
        document.getElementById(`overEditLabel-${editTeamMember.id}`).classList.add("active");
        document.getElementById(`bestuurEditLabel-${editTeamMember.id}`).classList.add("active");
        editTeamMember.imageFile = null;
    }
    
    return (
        <div>
            <Modal
                actions={[<Button flat modal="close" node="button">Annuleer</Button>]}
                header="Bewerk teamlid"
                id={`bewerk-modal-${editTeamMember.id}`}
                open={false}
                trigger={<Button className="flat orange darken-2"><FontAwesomeIcon icon={faUserEdit}/></Button>}
            >
                <form onSubmit={handleSubmit} id={`editTeamMemberForm-${editTeamMember.id}`} style={{marginTop:"0"}}>
                    <div className="input-field">
                        <label htmlFor={`naamEdit-${editTeamMember.id}`} id={`naamEditLabel-${editTeamMember.id}`} className="grey-text text-darken-3 active">Naam</label>
                        <input type="text" id={`naamEdit-${editTeamMember.id}`} onChange={e => editTeamMember.naamEdit = e.target.value} defaultValue={editTeamMember.naamEdit} required />
                    </div>
                    <div className="file-field input-field">
                        <div className="btn blue darken-2 z-depth-0" id="fotoButton">
                            <span htmlFor="fotoTeamMemberEdit">Foto</span>
                            <input type="file" id="fotoTeamMemberEdit" accept="image/*" onChange={handleFileChange} />
                        </div>
                        <div className="file-path-wrapper">
                            <input className="file-path" type="text" defaultValue={editTeamMember.fotoNameEdit}/>
                        </div>
                    </div>
                    <div className="input-field">
                        <label htmlFor={`functieEdit-${editTeamMember.id}`} id={`functieEditLabel-${editTeamMember.id}`} className="grey-text text-darken-3 active">Functie</label>
                        <input type="text" id={`functieEdit-${editTeamMember.id}`} onChange={e => editTeamMember.functieEdit = e.target.value} defaultValue={editTeamMember.functieEdit} required />
                    </div>
                    <div className="input-field">
                        <label htmlFor={`overEdit-${editTeamMember.id}`} id={`overEditLabel-${editTeamMember.id}`} className="grey-text text-darken-3 active">Over mij</label>
                        <textarea id={`overEdit-${editTeamMember.id}`} className="materialize-textarea" onChange={e => editTeamMember.overEdit = e.target.value} defaultValue={editTeamMember.overEdit} />
                    </div>
                    <p>
                        <label htmlFor={`bestuurEdit-${editTeamMember.id}`} id={`bestuurEditLabel-${editTeamMember.id}`} className="grey-text text-darken-3">
                            <input type="checkbox" id={`bestuurEdit-${editTeamMember.id}`} onChange={e => editTeamMember.bestuurEdit = e.target.checked} defaultChecked={editTeamMember.bestuurEdit} />
                            <span>Bestuurslid</span>
                        </label>
                    </p>
                    <div className="input-field">
                        <input type="submit" value="Veranderingen opslaan" className="btn blue darken-4 z-depth-0" id={`saveButton-${editTeamMember.id}`} />
                        <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                    </div>                
                </form>
            </Modal>
        </div>
    )
}

export default EditTeamMember;