import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import CreateNieuws from './CreateNieuws';
import Bericht from './Bericht';

function Nieuws (props) {    
    const { nieuws, profile } = props;
    
    useEffect(() => {
        document.title = `Nieuws`;
    });

    return (
        <div className="container">
        <br />
            {profile && profile.role === 'admin' ? <CreateNieuws/> : null}            
            <h4>Nieuws</h4>
            <hr />
            <div className="row">
                {nieuws && nieuws.map(bericht => {
                    return(
                        <Bericht bericht={bericht} key={bericht.id}/>
                    )
                })}
            </div>
        </div>
    ) 
}

const mapStateToProps = (state) => {
    return {
        nieuws: state.firestore.ordered.nieuws,
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'nieuws', orderBy: ['createdAt', 'desc'] }
    ])
)(Nieuws);