import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { MediaBox, Button, Modal } from 'react-materialize';
import M from "materialize-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';

const db = firebase.firestore();

function ShowFotoAlbum(props) {
    const { id } = useParams();
    const { profile } = props;
    const [albumData, setAlbumData] = useState(null);
    const [fotos, setFotos] = useState([]);

    const fetchAlbum = async () => {
        const albumDoc = await db.collection("fotoAlbums").doc(id).get();
        setAlbumData(albumDoc.data());
    }
    if(!albumData){
        fetchAlbum();
    }

    const deleteFoto = (fotoName, fotoUrl) => {
        const album = db.collection("fotoAlbums").doc(id);
        album.update({ 
            fotoUrls: firebase.firestore.FieldValue.arrayRemove({
                name: fotoName,
                url: fotoUrl
            })
        }).then(() => {
            const fotoRef = firebase.storage().ref().child(`fotos/albums/${albumData.albumName}/${fotoName}`);
            fotoRef.delete();
        });        
    }

    const handleFileChange = (e) => {
        if(e.target.files[0]){
            setFotos([...e.target.files]);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addFotos();
    }

    const addFotos = async () => {
        console.log("addFotos");
        var modalElem = document.getElementById(`addFotosModal`);
        var instance = M.Modal.getInstance(modalElem);

        const storageRef = firebase.storage().ref();
        const fileUploads = fotos.map(file => storageRef.child(`fotos/albums/${albumData.albumName}/${file.name}`).put(file));
        
        const files = (await Promise.all(fileUploads));
        const fileNames = files.map(f => f._delegate.metadata.name);
        const fileURLReqs = files.map(f => f.ref.getDownloadURL());
        const fileUrls = await Promise.all(fileURLReqs);

        const fileObjects = fileNames.map((f,i) => ({
            name: f,
            url: fileUrls[i]
        }));

        const album = db.collection("fotoAlbums").doc(id);
        album.update({ 
            fotoUrls: firebase.firestore.FieldValue.arrayUnion(...fileObjects)
         });

        instance.close();
    }

    const editName = () => {
        document.getElementById("album-name-edit").removeAttribute("disabled");
    }

    const changeName = () => {
        const newName = document.getElementById("album-name-edit").value;
        const album = db.collection("fotoAlbums").doc(id);
        album.update({ albumName: newName });
        document.getElementById("album-name-edit").setAttribute("disabled", true);
    }

    const resetForm = () => {
        document.getElementById(`addFotosForm`).reset();
        setFotos(null);
    }
    
    useEffect(() => {
        if(albumData){
            document.title = `${albumData.albumName}`;}
    });

    return (
        <div className="container" style={{overflow:"hidden", position:"relative"}}>
            {!albumData ? "Foto album laden..." :
            <div>
                <br/>
                {profile.role === 'admin' || profile.role === 'leraar' ? 
                <div>
                    <Modal
                        actions={[<Button flat modal="close" node="button">Annuleer</Button>]}
                        header={`Foto's toevoegen aan: ${albumData.albumName}`}
                        id={`addFotosModal`}
                        open={false}
                        trigger={<Button className="flat right orange darken-2 z-depth-0" style={{marginLeft:10}}><FontAwesomeIcon icon={faPlusSquare}/></Button>}
                    >
                        <form onSubmit={handleSubmit} id={`addFotosForm`} style={{marginTop:"0"}}>
                            <div className="file-field input-field">
                                <div className="btn blue darken-2 z-depth-0" id="fotoButton">
                                    <span htmlFor="fotoGroepEdit">Foto's</span>
                                    <input type="file" id="fotoGroepEdit" accept="image/*" multiple onChange={handleFileChange} />
                                </div>
                                <div className="file-path-wrapper">
                                    <input className="file-path" type="text" />
                                </div>
                            </div>
                            <div className="input-field">
                                <input type="submit" value="Foto's Uploaden" className="btn blue darken-4 z-depth-0" id={`saveButton`} />
                                <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                            </div>                
                        </form>
                    </Modal>
                    <h4>
                        Foto Album: <input type="text input-field" id="album-name-edit" defaultValue={albumData.albumName} disabled />
                        <button className= "btn" onClick={() => {editName()}}><FontAwesomeIcon icon={faEdit}/></button>
                        <button className= "btn" onClick={() => {changeName()}}><FontAwesomeIcon icon={faSave}/></button>
                    </h4>
                </div> :
                <h4>Foto Album: {albumData.albumName}</h4>}
                <hr />
                <div className="row">
                    {albumData.fotoUrls && albumData.fotoUrls.map(foto => {
                        return (
                            <div className="card col s12 m6 l4 orange z-depth-0" style={{padding:"0.5rem", margin:0}}>
                                <MediaBox id="team_foto">
                                    <img src={foto.url} alt="Foto" width="100%" />
                                </MediaBox> 
                                <button className="btn z-depth-0 red accent-4" id={`deleteButton_${albumData.id}`} onClick={() => {deleteFoto(foto.name, foto.url)}} style={{marginLeft:20}}><FontAwesomeIcon icon={faTrashAlt}/></button>
                            </div>
                        )
                    })}
                </div>
            </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(ShowFotoAlbum);