import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Button, Table } from 'react-materialize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';
import EditTeamMember from '../Team/EditTeamMember';

const db = firebase.firestore();

const TeamListDashboard = (props) => {  
    const { teamMembers } = props;
    const deleteMember = async (memberId) => {
        const member= await db.collection("teamMembers").doc(memberId).get();
        if(member.data().fotoName){
            const memberRef = firebase.storage().ref().child(`team/${member.data().fotoName}`);
            memberRef.delete().then(function() {       
                db.collection("teamMembers").doc(memberId).delete();
            }).catch(function(error) {
                console.log(error);
            }); 
        } else {
            db.collection("teamMembers").doc(memberId).delete();
        }      
    }

    return (
        <div>
            <Table className="responsive-table">
                <thead>
                    <tr>
                        <th data-field="naam">Naam</th>
                        <th data-field="functie">Functie</th>
                        <th data-field="heeftFoto">Heeft Foto</th>
                        <th data-field="over">Over</th>
                        <th data-field="bestuur">Bestuur</th>
                        <th data-field="modify"></th>
                        <th data-field="delete"></th>
                    </tr>
                </thead>
                <tbody>
                    { teamMembers && teamMembers.map(teamMember => {
                        return(
                            <tr key = {teamMember.id}>
                                <td>{teamMember.naam}</td>
                                <td>{teamMember.functie}</td>
                                <td>{teamMember.foto !== '' ? "Ja" : "Nee"}</td>
                                <td>{teamMember.over}</td>
                                <td>{teamMember.bestuur === true ? "Ja" : "Nee"}</td>
                                <td><EditTeamMember teammember={teamMember} /></td>
                                <td><Button className="red accent-4" onClick={() => deleteMember(teamMember.id)}><FontAwesomeIcon icon={faUserSlash}/></Button></td>
                            </tr>                            
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        teamMembers: state.firestore.ordered.teamMembers
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'teamMembers', orderBy: ['functie'] }
    ])
)(TeamListDashboard);