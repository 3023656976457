import firebase from 'firebase/app';

export const addFotoAlbum = (fotoAlbum) => {
    return (dispatch, { getFirebase, getFirestore }) => {
        const firestore = firebase.firestore();
        firestore.collection('fotoAlbums').add({
            ...fotoAlbum
        }).then(() => {
            dispatch({type: 'ADD_FOTOALBUM', fotoAlbum: fotoAlbum});
        }).catch((err) => {
            dispatch({type: 'ADD_FOTOALBUM_ERROR', err});
        });        
    }
}