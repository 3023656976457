import React, {useState, useEffect} from 'react';
import { MediaBox } from 'react-materialize';
import firebase from '../../config/fbConfig';
import { connect } from 'react-redux';
import TeamList from './TeamList';

const db = firebase.firestore();

function Team(props) {
    const { profile } = props;
    const [downloadUrl, setDownloadUrl] = useState('');

    useEffect(() => {
        const fetchDownloadUrl = async () => {
            const downloadUrl = await db.collection("fileLinks").doc("teamfoto").get();
            setDownloadUrl(downloadUrl.data().url);
        }
        fetchDownloadUrl();
        
        document.title = `Ons Team`;
    })

    return (
        <div className="container">
        <br />
            <h4>Ons Team</h4>
            <MediaBox id="team_foto">
                <img src={downloadUrl ? downloadUrl : "img/team_placeholder.png"} alt="Ons Team" width="60%"/>
            </MediaBox>     
            {profile.role === 'admin' ?
                <div className="center-align">
                    
                </div>
            : null }
            <hr/>
            <div className="row">
                <TeamList />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Team);