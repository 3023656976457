import React from 'react';
import { Button, Modal } from 'react-materialize';
import { connect } from 'react-redux';
import { addGroepsBericht } from '../../store/actions/groepsBerichtActions';
import { Redirect } from 'react-router-dom';
import firebase from '../../config/fbConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import M from "materialize-css";
import { v4 } from 'uuid';

const db = firebase.firestore();

function CreateGroepsBericht(props) {
    const { auth, groepId } = props;
    var newGroepsBericht = {
        id: `${groepId}_${v4()}`,
        titel: '',
        fotoUrl: '',
        fotoName: '',
        tekst: '',
        createdAt: new Date(),
        imageFile: null
    }

    const resetForm = () => {
        document.getElementById("groepsBerichtForm").reset();
        newGroepsBericht = {
            id: `${groepId}_${v4()}`,
            titel: '',
            fotoUrl: '',
            fotoName: '',
            tekst: '',
            createdAt: new Date(),
            imageFile: null
        }

        var inputs = document.forms["groepsBerichtForm"].getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = false;            
        }
        inputs = document.forms["groepsBerichtForm"].getElementsByTagName('textarea');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = false;            
        }
        document.getElementById("groepsBerichtButton").classList.remove("disabled");
        document.getElementById("fotoButton").classList.remove("disabled");
    }

    const handleFileChange = async (e) => {
        if(e.target.files[0]){
            const image = e.target.files[0];
            newGroepsBericht.imageFile = image;
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        var modalElem = document.getElementById("newGroepsBerichtModal");
        var instance = M.Modal.getInstance(modalElem);
        var inputs = document.forms["groepsBerichtForm"].getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = true;            
        }
        inputs = document.forms["groepsBerichtForm"].getElementsByTagName('textarea');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = true;            
        }
        document.getElementById("groepsBerichtButton").classList.add("disabled");
        document.getElementById("fotoButton").classList.add("disabled");

        if(newGroepsBericht.imageFile){
            const uploadTask = firebase.storage().ref(`groepen/berichten/${newGroepsBericht.imageFile.name}`).put(newGroepsBericht.imageFile);
            uploadTask.on('state_changed', 
            (snapshot) => {}, 
            (error) => { console.log(error); }, 
            () => {
                firebase.storage().ref('groepen/berichten').child(newGroepsBericht.imageFile.name).getDownloadURL().then((fotoUrl) => {
                    newGroepsBericht.fotoUrl = fotoUrl;
                    newGroepsBericht.fotoName = newGroepsBericht.imageFile.name;
                    var groep = db.collection("groepen").doc(groepId);
                    groep.update({
                        berichten: firebase.firestore.FieldValue.arrayUnion({
                            id: newGroepsBericht.id,
                            titel: newGroepsBericht.titel,
                            fotoUrl: newGroepsBericht.fotoUrl, 
                            fotoName: newGroepsBericht.fotoName,
                            tekst: newGroepsBericht.tekst, 
                            createdAt: new Date()
                        })
                    });
                    instance.close();               
                });
            });  
        } else {
            var groep = db.collection("groepen").doc(groepId);
            groep.update({
                berichten: firebase.firestore.FieldValue.arrayUnion({
                    id: newGroepsBericht.id,
                    titel: newGroepsBericht.titel,
                    fotoUrl: newGroepsBericht.fotoUrl, 
                    fotoName: newGroepsBericht.fotoName,
                    tekst: newGroepsBericht.tekst, 
                    createdAt: new Date()
                })
            });
            instance.close();
        }    
    }
    if( !auth.uid) return <Redirect to='/' />
    return (
        <div>
            <Modal
                actions={[<Button flat modal="close" node="button">Annuleer</Button>]}
                header="Bericht Plaatsen"
                id="newGroepsBerichtModal"
                open={false}
                trigger={<Button className="btn-floating right blue darken-4 z-depth-0"><FontAwesomeIcon icon={faNewspaper} size="lg"/></Button>}
                options={{
                    onCloseStart: () => {resetForm()}
                }}
            >
                <form onSubmit={handleSubmit} id="groepsBerichtForm" style={{marginTop:"0"}}>
                    <div className="input-field">
                        <label htmlFor="titelGroepsBericht" className="grey-text text-darken-3">Titel</label>
                        <input type="text" id="titelGroepsBericht" onChange={e => newGroepsBericht.titel = e.target.value} required />
                    </div>
                    <div className="file-field input-field">
                        <div className="btn blue darken-2 z-depth-0" id="fotoButton">
                            <span htmlFor="fotoGroepsBericht">Foto</span>
                            <input type="file" id="fotoGroepsBericht" accept="image/*" onChange={handleFileChange} />
                        </div>
                        <div className="file-path-wrapper">
                            <input className="file-path" type="text"/>
                        </div>
                    </div>
                    <div className="input-field">
                        <label htmlFor="tekst" className="grey-text text-darken-3">Tekst</label>
                        <textarea type="text" id="tekst" className="materialize-textarea" onChange={e => newGroepsBericht.tekst = e.target.value} required />
                    </div>
                    <div className="input-field">
                        <button className="btn blue darken-4 z-depth-0" id="groepsBerichtButton">Plaatsen</button>
                        <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addGroepsBericht: (bericht) => dispatch(addGroepsBericht(bericht))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroepsBericht);