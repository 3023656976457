const initState = {
    fotoAlbum: [
        {id: '1', albumName: 'FotoAlbum', fotoUrls: [], createdAt: new Date()}
    ]
}

const fotoAlbumReducer = (state = initState, action) => {
    switch (action.type){
        case 'ADD_FOTOALBUM':
            console.log('added fotoAlbum', action.fotoAlbum);
            return state;
        case 'ADD_FOTOALBUM_ERROR':
            console.log('adding fotoAlbum error', action.err);
            return state;
        default:
            return state;
    }
}

export default fotoAlbumReducer;