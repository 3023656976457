import firebase from 'firebase/app';

export const addVacature = (vacature) => {
    return (dispatch) => {
        const firestore = firebase.firestore();
        firestore.collection('vacatures').add({
            ...vacature
        }).then(() => {
            dispatch({type: 'ADD_VACATURE', vacature});
        }).catch((err) => {
            dispatch({type: 'ADD_VACATURE_ERROR', err});
        });        
    }
}