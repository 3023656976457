import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Button, Table } from 'react-materialize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';
import { DateTime } from 'luxon';
import EditVacature from '../Vacatures/EditVacature';

const db = firebase.firestore();

const VacatureList = (props) => {  
    const { vacatures } = props;
    const deleteVacature = (vacatureId) => {
        db.collection("vacatures").doc(vacatureId).delete();
    }
    return (
        <div>
            <Table className="responsive-table">
                <thead>
                    <tr>
                        <th data-field="titel">Titel</th>
                        <th data-field="einddatum">Verloopt op</th>
                        <th data-field="edit"></th>
                        <th data-field="delete"></th>
                    </tr>
                </thead>
                <tbody>
                    { vacatures && vacatures.map(vacature => {
                        return(
                            <tr key = {vacature.id}>
                                <td>{vacature.titel}</td>
                                <td>{DateTime.fromJSDate(vacature.einddatum.toDate()).toLocaleString()}</td>
                                <td><EditVacature vacature={vacature} /></td>
                                <td><Button className="red accent-4" onClick={() => deleteVacature(vacature.id)}><FontAwesomeIcon icon={faTrashAlt}/></Button></td>
                            </tr>                            
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        vacatures: state.firestore.ordered.vacatures
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'vacatures', orderBy: ['einddatum'] }
    ])
)(VacatureList);