import React from 'react';

const TeamMember = ({teamMember}) => {
    return (
        <div className="card horizontal yellow lighten-4">
            <div className="card-image">
                <img src={teamMember.foto ? teamMember.foto : "img/default_profile_img.png"} alt="Profiel Foto"/>
                <span className="card-title"><b>{teamMember.functie}</b></span>
            </div>
            <div className="card-stacked">
                <div className="card-title">{teamMember.naam}</div>
                <div className="card-content">
                    {teamMember.over}        
                </div>
            </div>            
        </div>
    )
}

export default TeamMember;