import React from 'react';
import { compose } from 'redux';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { signOut } from '../../store/actions/authActions';
import { SideNav, Button, Collapsible, CollapsibleItem } from 'react-materialize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserLock, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import SignIn from '../auth/SignIn';
import { forwardRef } from 'react';

const Navbar = forwardRef((props, ref) => {
    const { auth, profile, groepen } = props;
    const logInLink = !auth.uid ? <li><NavLink className="black-text nav-link-5 sidenav-close" to="/signin"><FontAwesomeIcon icon={faUserLock} /> Log In</NavLink></li> : null;
    const logOutLink = auth.uid ? <li><NavLink className="nav-link-7 sidenav-close orange darken-2" to="/" onClick={props.signOut}><FontAwesomeIcon icon={faUserTimes} /> Log Out</NavLink></li> : null;
    
    // const MenuButton = React.forwardRef((props, ref) => 
    //     <Button {...props} node="button" className="btn-large red accent-4">
    //         <FontAwesomeIcon icon={faBars} />
    //         <b className="hide-on-small-only">Menu</b>
    //     </Button>
    // )
    
    return (
        <div className="navbar">
        <SideNav
            id="SideNav-Menu"
            className="light-blue lighten-2 sidenav-fixed"
            options={{
            draggable: true
            }}
            trigger={<Button node="button" className="btn-large red accent-4">
                <FontAwesomeIcon icon={faBars} />
                <b className="hide-on-small-only"> Menu</b>
            </Button>}
        >
            <Collapsible accordion>
                <NavLink to="/"><div className="side-nav-logo"></div></NavLink>
                <div className="center-align nav-link-6"><NavLink to="/" className="side-nav-title sidenav-close"><h5><b>Het Kleurenorkest</b></h5></NavLink></div>
                { auth.isLoaded && logInLink ? 
                    <CollapsibleItem
                    expanded={false}
                    header={<div><FontAwesomeIcon icon={faUserLock} /> Log In</div>}
                    node="div"
                    className="nav-link-5"
                    >
                        <ul>
                            <li><SignIn /></li>
                        </ul>
                    </CollapsibleItem>
                : null }
                { auth.isLoaded && logOutLink ?
                    <CollapsibleItem
                    expanded={false}
                    header={auth.displayName}
                    node="div"
                    className="nav-link-5 orange darken-2"
                    >
                        <ul className="orange darken-1">
                            <li>
                                <div className="center-align">
                                    <i className="grey-text text-darken-2 capitalize">{profile.role}</i>
                                </div>
                            </li>
                            <li><NavLink to='/account' className="sidenav-close">Account</NavLink></li>
                            {profile.role === 'admin' ? <li><NavLink to='/dashboard' className="sidenav-close">Dashboard</NavLink></li> : null}
                            <li><NavLink to='/nieuwsbrieven' className="sidenav-close">Nieuwsbrieven</NavLink></li>
                            <li><NavLink to='/fotoalbums' className="sidenav-close">Foto Albums</NavLink></li>
                            {logOutLink}
                        </ul>
                    </CollapsibleItem>
                : null } 
                <CollapsibleItem
                    expanded={false}
                    header="School"
                    node="div"
                    className="nav-link-1"
                >
                    <ul>
                        <li><NavLink to='/school' className="black-text yellow accent-2 sidenav-close">De School</NavLink></li>
                        <li><NavLink to='/missie' className="black-text yellow accent-2 sidenav-close">Missie en Visie</NavLink></li>
                        <li><NavLink to='/nob' className="black-text yellow accent-2 sidenav-close">NOB</NavLink></li>
                        <li><NavLink to='/bestuur' className="black-text yellow accent-2 sidenav-close">Bestuur</NavLink></li>
                        <li><NavLink to='/team' className="black-text yellow accent-2 sidenav-close">Ons Team</NavLink></li>
                        <li><NavLink to='/vacatures' className="black-text yellow accent-2 sidenav-close">Vacatures</NavLink></li>
                        {/* <li><NavLink to='/vrijedagen' className="black-text yellow accent-2 sidenav-close">Vrije Dagen/ Vakanties</NavLink></li> */}
                    </ul>    
                </CollapsibleItem>
                <CollapsibleItem
                    expanded={false}
                    header="Groepen"
                    node="div"
                    className="nav-link-2 black-text"
                >
                    <ul>
                        {groepen && groepen.map(groep => {
                            return(
                                <li key={groep.id}><NavLink to={`/groepen/${groep.id}`} className="white-text red accent-4 sidenav-close">{groep.naam}</NavLink></li>
                            )
                        })}
                    </ul>    
                </CollapsibleItem>
                <li><NavLink to='/nieuws' className="nav-link-3 sidenav-close" style={{marginLeft: "-16px"}}>Nieuws</NavLink></li>               
                <li><NavLink to='/kalender' className="nav-link-4 sidenav-close" style={{marginLeft: "-16px"}}>Kalender</NavLink></li>               
            </Collapsible>
        </SideNav>
        </div>
    )
})

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        groepen: state.firestore.ordered.groepen
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'groepen', orderBy: ['naam'] }
    ])
)(Navbar);