import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Button, Modal } from 'react-materialize';
import M from "materialize-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { Select } from 'react-materialize';
import { useParams } from 'react-router';

const db = firebase.firestore();

const EditGroep = ({ groep, users }) => {
    const { id } = useParams();
    const editGroep = {
        id: id,
        naamEdit: groep.naam,
        fotoUrlEdit: groep.fotoUrl,
        fotoNameEdit: groep.fotoName,
        introTekstEdit: groep.introTekst,
        leraarEdit: groep.leraar,
        berichtenEdit: groep.berichten,
        imageFile: null
    }
    
    const handleFileChange = async (e) => {
        if(e.target.files[0]){
            const image = e.target.files[0];
            editGroep.imageFile = image;
        }
    }

    const handleChange = () => {
        const select = document.getElementById(`leraarEdit-${editGroep.id}`);
        editGroep.leraarEdit = select.value;
        console.log("Value:", select.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        editGroepFunc(editGroep);
    }

    const editGroepFunc = (editedGroep) => {
        var saveButton = document.getElementById(`saveButton-${editedGroep.id}`);
        saveButton.value = "Opslaan...";
        saveButton.classList.add("disabled");
        var modalElem = document.getElementById(`bewerk-modal-${editedGroep.id}`)
        var instance = M.Modal.getInstance(modalElem);

        if(editedGroep.imageFile){
            const uploadTask = firebase.storage().ref(`groepen/${editedGroep.imageFile.name}`).put(editedGroep.imageFile);
            uploadTask.on('state_changed', 
            (snapshot) => {}, 
            (error) => { console.log(error); }, 
            () => {
                firebase.storage().ref('groepen').child(editedGroep.imageFile.name).getDownloadURL().then((fotoUrl) => {
                    if(editedGroep.fotoNameEdit){
                        const groepFotoRef = firebase.storage().ref().child(`groepen/${editedGroep.fotoNameEdit}`);
                        groepFotoRef.delete().catch(function(error) {
                            console.log(error);
                        });
                    }
                    editedGroep.fotoUrlEdit = fotoUrl;
                    editedGroep.fotoNameEdit = editedGroep.imageFile.name;
                    db.collection("groepen").doc(editedGroep.id).update({
                        naam: editedGroep.naamEdit,
                        fotoUrl: editedGroep.fotoUrlEdit,
                        fotoName: editedGroep.fotoNameEdit,
                        introTekst: editedGroep.introTekstEdit,
                        leraar: editedGroep.leraarEdit
                    }).then(() => {
                        saveButton.value = "Verandering opslaan";
                        saveButton.classList.remove("disabled");
                        instance.close();
                        resetForm();
                    }).catch((err) => {
                        console.log(err.message);
                    });                
                });
            });
        } else {
            db.collection("groepen").doc(editedGroep.id).update({
                naam: editedGroep.naamEdit,
                introTekst: editedGroep.introTekstEdit,
                leraar: editedGroep.leraarEdit
            }).then(() => {
                saveButton.value = "Verandering opslaan";
                saveButton.classList.remove("disabled");
                instance.close();
                resetForm();
            }).catch((err) => {
                console.log(err.message);
            });
        }        
    }

    const resetForm = () => {
        document.getElementById(`editGroepForm-${editGroep.id}`).reset();
        document.getElementById(`naamEditLabel-${editGroep.id}`).classList.add("active");
        document.getElementById(`introTekstEditLabel-${editGroep.id}`).classList.add("active");
        editGroep.imageFile = null;
    }
    
    return (
        <Modal
            actions={[<Button flat modal="close" node="button">Annuleer</Button>]}
            header={`Bewerk groep: ${groep.naam}`}
            id={`bewerk-modal-${editGroep.id}`}
            open={false}
            trigger={<Button className="flat right orange darken-2 z-depth-0" style={{marginLeft:10}}><FontAwesomeIcon icon={faUserEdit}/></Button>}
        >
            {!users ? "Groeps informatie laden..." :
            <form onSubmit={handleSubmit} id={`editGroepForm-${editGroep.id}`} style={{marginTop:"0"}}>
                <div className="input-field">
                    <label htmlFor={`naamEdit-${editGroep.id}`} id={`naamEditLabel-${editGroep.id}`} className="grey-text text-darken-3 active">Naam</label>
                    <input type="text" id={`naamEdit-${editGroep.id}`} onChange={e => editGroep.naamEdit = e.target.value} defaultValue={editGroep.naamEdit} required />
                </div>
                <div className="file-field input-field">
                    <div className="btn blue darken-2 z-depth-0" id="fotoButton">
                        <span htmlFor="fotoGroepEdit">Foto</span>
                        <input type="file" id="fotoGroepEdit" accept="image/*" onChange={handleFileChange} />
                    </div>
                    <div className="file-path-wrapper">
                        <input className="file-path" type="text" defaultValue={editGroep.fotoNameEdit}/>
                    </div>
                </div>
                <div className="input-field">
                    <label htmlFor={`introTekstEdit-${editGroep.id}`} id={`introTekstEditLabel-${editGroep.id}`} className="grey-text text-darken-3 active">Intro Tekst</label>
                    <textarea id={`introTekstEdit-${editGroep.id}`} className="materialize-textarea" onChange={e => editGroep.introTekstEdit = e.target.value} defaultValue={editGroep.introTekstEdit} />
                </div>
                <div className="file-field input-field">
                    <Select
                        id={`leraarEdit-${editGroep.id}`}
                        multiple={false}
                        onChange={() => {handleChange()}}
                        options={{
                            classes: '',
                            dropdownOptions: {
                                alignment: 'left',
                                autoTrigger: true,
                                closeOnClick: true,
                                constrainWidth: true,
                                coverTrigger: true,
                                hover: false,
                                inDuration: 150,
                                onCloseEnd: null,
                                onCloseStart: null,
                                onOpenEnd: null,
                                onOpenStart: null,
                                outDuration: 250
                            }
                        }}
                        value={editGroep.leraarEdit}
                    >
                        <option
                            disabled
                            value=""
                        >
                            Kies een leraar
                        </option>
                        {users && users.map(user => {
                            if(user.role === 'admin' || user.role === 'leraar'){
                                return(
                                    <option value={user.realName} key={user.id}>{user.realName}</option>
                                )
                            }
                            return null;
                        })}
                    </Select> 
                </div>
                <div className="input-field">
                    <input type="submit" value="Veranderingen opslaan" className="btn blue darken-4 z-depth-0" id={`saveButton-${editGroep.id}`} />
                    <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                </div>                
            </form>}
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        users: state.firestore.ordered.users
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'users' }
    ])
)(EditGroep);