import firebase from 'firebase/app';

export const addTeamMember = (teamMember) => {
    return (dispatch, { getFirebase, getFirestore }) => {
        const firestore = firebase.firestore();
        firestore.collection('teamMembers').add({
            ...teamMember
        }).then(() => {
            dispatch({type: 'ADD_TEAMMEMBER', teamMember});
        }).catch((err) => {
            dispatch({type: 'ADD_TEAMMEMBER_ERROR', err});
        });        
    }
}