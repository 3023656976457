import VrijeDagenList from './VrijeDagenList';

function VrijeDagen(props) {
    return (
        <div className="container">
            <div>
            <br />
                <h4>Vrije Dagen en Vakanties</h4>
                <hr />
            </div>
            <VrijeDagenList/>
        </div>
    )
}

export default VrijeDagen;