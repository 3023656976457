import React, {useState} from 'react';
import firebase from 'firebase/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

function Banner() {
    const [settings, setSettings] = useState(null);

    const fetchSettings = async () => {
        const settingsDoc = await firebase.firestore().collection('settings').doc('banner').get();
        setSettings(settingsDoc.data());
    }
    if(!settings){
        fetchSettings();
    }

    return (
        <div>
            {settings && settings.showBanner ? 
            <div className="center-align light-green accent-3 shadow banner-splash">
                <div className='container banner-block'>
                    <h6 className="slogan white-text black blink-text" style={{fontWeight:"bold"}}>{settings.bannerTekst}
                        {settings.fileLink !== "" ? 
                            <a href={settings.fileLink} target="_blank" rel="noreferrer" className="blue darken-4 btn" style={{fontWeight:"normal", marginLeft: "16px"}}>
                                {settings.knopTekst} <FontAwesomeIcon icon={faExternalLinkAlt}/>
                            </a> 
                        : null}
                    </h6>
                </div>                
            </div> : null}
        </div>
    )
}

export default Banner;