import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { addGroep } from '../../store/actions/groepActions';
import { Redirect } from 'react-router-dom';
import firebase from '../../config/fbConfig';
import { Select } from 'react-materialize';

function CreateGroep(props) {
    const { auth, users } = props;
    var newGroep = {
        naam: '',
        fotoUrl: '',
        fotoNaam: '',
        introTekst: '',
        leraar: '',
        berichten: [],
        imageFile: null
    }

    const resetForm = () => {
        document.getElementById("groepForm").reset();
        newGroep = {
            naam: '',
            fotoUrl: '',
            fotoNaam: '',
            introTekst: '',
            leraar: '',
            berichten: [],
            imageFile: null
        }
        
        const select = document.getElementById("leraarSelect");
        select.value = null;

        var inputs = document.forms["groepForm"].getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = false;            
        }
        inputs = document.forms["groepForm"].getElementsByTagName('textarea');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = false;            
        }
        document.getElementById("groepButton").classList.remove("disabled");
        document.getElementById("fotoButton").classList.remove("disabled");
    }

    const handleFileChange = async (e) => {
        if(e.target.files[0]){
            const image = e.target.files[0];
            newGroep.imageFile = image;
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        var inputs = document.forms["groepForm"].getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = true;            
        }
        inputs = document.forms["groepForm"].getElementsByTagName('textarea');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = true;            
        }
        document.getElementById("groepButton").classList.add("disabled");
        document.getElementById("fotoButton").classList.add("disabled");

        if(newGroep.imageFile){
            const uploadTask = firebase.storage().ref(`groepen/${newGroep.imageFile.name}`).put(newGroep.imageFile);
            uploadTask.on('state_changed', 
            (snapshot) => {}, 
            (error) => { console.log(error); }, 
            () => {
                firebase.storage().ref('groepen').child(newGroep.imageFile.name).getDownloadURL().then((fotoUrl) => {
                    newGroep.fotoUrl = fotoUrl;
                    newGroep.fotoNaam = newGroep.imageFile.name;
                    props.addGroep({
                        naam: newGroep.naam,
                        fotoUrl: newGroep.fotoUrl,
                        fotoNaam: newGroep.fotoNaam,
                        introTekst: newGroep.introTekst,
                        leraar: newGroep.leraar,
                        berichten: []
                    });  
                    resetForm();             
                });
            });  
        } else {
            props.addGroep({
                naam: newGroep.naam,
                fotoUrl: newGroep.fotoUrl,
                fotoNaam: newGroep.fotoNaam,
                introTekst: newGroep.introTekst,
                leraar: newGroep.leraar,
                berichten: []
            });
            resetForm();
        }    
    }

    const handleChange = () => {
        const select = document.getElementById("leraarSelect");
        newGroep.leraar = select.value;
    }

    if( !auth.uid) return <Redirect to='/' />
    return (
        <div>
        {users ? 
            <form onSubmit={handleSubmit} id="groepForm" style={{marginTop:"0"}}>
                <div className="input-field">
                    <label htmlFor="naamGroep" className="grey-text text-darken-3">Naam</label>
                    <input type="text" id="naamGroep" onChange={e => newGroep.naam = e.target.value} required />
                </div>
                <div className="file-field input-field">
                    <div className="btn blue darken-2 z-depth-0" id="fotoButton">
                        <span htmlFor="fotoGroep">Foto</span>
                        <input type="file" id="fotoGroep" accept="image/*" onChange={handleFileChange} />
                    </div>
                    <div className="file-path-wrapper">
                        <input className="file-path" type="text"/>
                    </div>
                </div>
                <div className="input-field">
                    <label htmlFor="introTekst" className="grey-text text-darken-3">Intro Tekst</label>
                    <textarea type="text" id="introTekst" className="materialize-textarea" onChange={e => newGroep.introTekst = e.target.value} required />
                </div>
                <div className="file-field input-field">
                    <Select
                        id="leraarSelect"
                        multiple={false}
                        onChange={() => {handleChange()}}
                        options={{
                            classes: '',
                            dropdownOptions: {
                                alignment: 'left',
                                autoTrigger: true,
                                closeOnClick: true,
                                constrainWidth: true,
                                coverTrigger: true,
                                hover: false,
                                inDuration: 150,
                                onCloseEnd: null,
                                onCloseStart: null,
                                onOpenEnd: null,
                                onOpenStart: null,
                                outDuration: 250
                            }
                        }}
                        value=""
                    >
                        <option
                            disabled
                            value=""
                        >
                            Kies een leraar
                        </option>
                        {users.map(user => {
                            if(user.role === 'admin' || user.role === 'leraar'){
                                return(
                                    <option value={user.realName} key={user.id}>{user.realName}</option>
                                )
                            }
                            return null;
                        })}
                    </Select> 
                </div>
                <div className="input-field">
                    <button className="btn blue darken-4 z-depth-0" id="groepButton">Maken</button>
                    <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                </div>
            </form> : null}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        users: state.firestore.ordered.users
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addGroep: (groep) => dispatch(addGroep(groep))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'users' }
    ])
)(CreateGroep);