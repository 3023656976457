import React, { useState } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Button, Table } from 'react-materialize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';
import 'firebase/functions';
import EditUser from '../auth/EditUser';

const UserList = (props) => {
    const {users, auth} = props;
    const [userList, setUserList] = useState(users);  
    const deleteUser = (userId) => {
        const deleteUser = firebase.functions().httpsCallable('deleteUser');

        deleteUser(userId).then((resp) => {
            console.log(resp.data.result);
            setUserList(userList.filter(item => item.id !== userId));
        }).catch((err) => {
            console.log(err.message);
        });
    }
    return (
        <div>
            <Table className="responsive-table">
                <thead>
                    <tr>
                        <th data-field="naam">Echte Naam</th>
                        <th data-field="gebruikersnaam">Gebruikers Naam</th>
                        <th data-field="email">Email Adres</th>
                        <th data-field="authorisatie">Authorisatie</th>
                        <th data-field="modify"></th>
                        <th data-field="delete"></th>
                    </tr>
                </thead>
                <tbody>
                    { users && users.map(user => {
                        let isSelf = auth.uid === user.id;
                        return(
                            <tr key = {user.id} className={isSelf ? "yellow accent-4 bold-text" : null}>
                                <td>{user.realName}</td>
                                <td>{user.userName}</td>
                                <td>{user.email}</td>
                                <td className="capitalize">{user.role}</td>
                                <td><EditUser user={user} /></td>
                                <td>{!isSelf ? <Button className="red accent-4" onClick={() => deleteUser(user.id)}><FontAwesomeIcon icon={faUserSlash}/></Button> : null}</td>
                            </tr>                            
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        users: state.firestore.ordered.users
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'users', orderBy: [['role']] }
    ])
)(UserList);