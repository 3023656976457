import { DateTime } from 'luxon';
import { MediaBox } from 'react-materialize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';
import { connect } from 'react-redux';
import EditNieuws from './EditNieuws';

const db = firebase.firestore();

function Bericht(props){
    const { profile, bericht } = props;
    
    const deleteBericht = async () => {
        const berichtDoc= await db.collection("nieuws").doc(bericht.id).get();
        if(berichtDoc.data().fotoName){
            const berichtRef = firebase.storage().ref().child(`nieuws/${berichtDoc.data().fotoName}`);
            berichtRef.delete().then(function() {       
                db.collection("nieuws").doc(bericht.id).delete();
            }).catch(function(error) {
                console.log(error);
            }); 
        } else {
            db.collection("nieuws").doc(bericht.id).delete();
        }      
    }

    const ToggleReadMore = () => {
        document.getElementById(`nieuwsTekst_${bericht.id}`).classList.toggle("line-clamp");
        var b = document.getElementById(`readMoreButton_${bericht.id}`);
        b.innerHTML = b.innerHTML === "Lees Meer" ? "Lees Minder" : "Lees Meer";
    }

    return(
        <div className="col s12 l6">
            <div className="card blue lighten-4">
                {bericht.foto ?
                    <div style={{padding:"1rem"}}>
                        <MediaBox>
                            <img src={bericht.foto} alt="Nieuws foto" width="100%" />
                        </MediaBox>
                    </div>
                : null}            
                <div className="card-content">
                    <span className="card-title"><b>{bericht.titel}</b></span>
                    <p className="grey-text">{DateTime.fromJSDate(bericht.createdAt.toDate()).toLocaleString(DateTime.DATETIME_MED)}</p>
                    <p className="line-clamp text-formatting" id={`nieuwsTekst_${bericht.id}`}>{`${bericht.tekst}`}</p>
                </div>
                <div className="card-action">
                    <button className="btn z-depth-0 blue" id={`readMoreButton_${bericht.id}`} onClick={ToggleReadMore}>Lees Meer</button>
                    {profile && profile.role === 'admin' ? 
                        <div className="right">
                            <EditNieuws nieuws={bericht} />
                            <button className="btn z-depth-0 red accent-4" id={`deleteButton_${bericht.id}`} onClick={deleteBericht} style={{marginLeft:20}}><FontAwesomeIcon icon={faTrashAlt}/></button> 
                        </div>
                    : null}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Bericht);