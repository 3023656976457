import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Table } from 'react-materialize';
import { DateTime } from 'luxon';

function VrijeDagenList(props) {
    const { vrijedagen } = props;

    return (
        <Table className="responsive-table yellow lighten-4">
            <thead>
                <tr>
                    <th data-field="titel">Titel</th>
                    <th data-field="begindatum">Begindatum</th>
                    <th data-field="einddatum">Einddatum</th>
                </tr>
            </thead>
            <tbody>
                { vrijedagen && vrijedagen.map(vrijedag => {
                    return(
                        <tr key = {vrijedag.id}>
                            <td>{vrijedag.titel}</td>
                            <td>{DateTime.fromJSDate(vrijedag.begindatum.toDate()).toLocaleString()}</td>
                            <td>{DateTime.fromJSDate(vrijedag.einddatum.toDate()).toLocaleString()}</td>
                        </tr>                            
                    )
                })}
            </tbody>
        </Table>
    )
}

const mapStateToProps = (state) => {
    return {
        vrijedagen: state.firestore.ordered.vrijedagen
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'vrijedagen', orderBy: ['begindatum'] }
    ])
)(VrijeDagenList);