const initState = {
    groep: [{
        id: '1', 
        naam: 'Groep', 
        fotoUrl: '', 
        fotoNaam: '', 
        introTekst: 'Intro Tekst', 
        leraar: '', 
        berichten: []
    }]
}

const groepReducer = (state = initState, action) => {
    switch (action.type){
        case 'ADD_GROEP':
            console.log('added groep', action.groep);
            return state;
        case 'ADD_GROEP_ERROR':
            console.log('adding groep error', action.err);
            return state;
        default:
            return state;
    }
}

export default groepReducer;