import React, {useState, useEffect} from 'react';
import { Button } from 'react-materialize';
import firebase from '../../config/fbConfig';

const db = firebase.firestore();

function ChangeSchoolgids (props) {
    const { extra } = props;
    const uploadButton = document.getElementById("uploadSchoolgidsButton");

    const [schoolgidsFile, setSchoolgidsFile] = useState(null);

    var finishedUploading = async () => {
        document.getElementById("uploadSchoolgidsForm").reset();

        uploadButton.innerText = "Vervang Schoolgids";
        uploadButton.classList.remove("disabled");

        const oldFile = await db.collection("fileLinks").doc("schoolgids").get();
        const oldFileRef = firebase.storage().ref().child(`schoolgids/${oldFile.data().filename}`);
        const URL = await firebase.storage().ref().child(`schoolgids/${schoolgidsFile.name}`).getDownloadURL(); 
        oldFileRef.delete().then(function() {       
            db.collection("fileLinks").doc("schoolgids").set({
                filename: schoolgidsFile.name,
                url: URL
            });
        }).catch(function(error) {
            console.log(error);
        });        
    }
    var uploadStatus = function(snapshot) {
        var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        uploadButton.innerText = `Uploaden: ${percentage.toFixed(2)}%`;
    }
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file !== null) console.log(file);
        setSchoolgidsFile(file);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        uploadButton.innerText = "Uploaden...";
        uploadButton.classList.add("disabled");

        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`schoolgids/${schoolgidsFile.name}`);
        const uploadTask = fileRef.put(schoolgidsFile);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        {
            'next': uploadStatus,
            'complete': finishedUploading
        });        
    }

    useEffect(() => {
        if(uploadButton !== null) {
            if(schoolgidsFile) {
                uploadButton.classList.remove("disabled");
            } else {
                uploadButton.classList.add("disabled");
            }
        }
    })

    return (
        <div className={extra}>
            <form id="uploadSchoolgidsForm" onSubmit={handleSubmit} style={{marginTop:"0", padding:"0"}}>
                <input type="file" accept=".pdf" onChange={handleFileChange} />
                <Button id="uploadSchoolgidsButton" className="orange darken-2 disabled" style={{marginLeft:"16px"}}>Vervang Schoolgids</Button>
            </form>                       
        </div>
    ) 
}

export default ChangeSchoolgids;