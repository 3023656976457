import React, {useState} from 'react';
import firebase from 'firebase/app';

function ContactSettings(){
    const [contact, setContact] = useState(null);

    const fetchSettings = async () => {
        const contactDoc = await firebase.firestore().collection('settings').doc('contact').get();
        setContact(contactDoc.data());
    }
    if(!contact){
        fetchSettings();
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); 

        await firebase.firestore().collection('settings').doc('contact').update(contact); 
        window.location.reload();
    }

    return (
        <div>
            {!contact ? "Teksten laden..." :
            <form onSubmit={handleSubmit} id="memberForm" style={{marginTop:"0"}}>
                <div className="input-field">
                    <label htmlFor='naam'className="grey-text text-darken-3 active">Naam</label>
                    <textarea id='naam' className="materialize-textarea" onChange={e => contact.naam = e.target.value} defaultValue={contact.naam} />
                </div>
                <br/>
                <div className="input-field">
                    <label htmlFor='adres'className="grey-text text-darken-3 active">Adres</label>
                    <textarea id='adres' className="materialize-textarea" onChange={e => contact.adres = e.target.value} defaultValue={contact.adres} />
                </div>
                <br/>
                <div className="input-field">
                    <label htmlFor='plaats'className="grey-text text-darken-3 active">Plaats</label>
                    <textarea id='plaats' className="materialize-textarea" onChange={e => contact.plaats = e.target.value} defaultValue={contact.plaats} />
                </div>
                <br/>
                <div className="input-field">
                    <label htmlFor='telefoon'className="grey-text text-darken-3 active">Telefoonnummer</label>
                    <textarea id='telefoon' className="materialize-textarea" onChange={e => contact.telefoon = e.target.value} defaultValue={contact.telefoon} />
                </div>
                <br/>
                <div className="input-field">
                    <label htmlFor='email'className="grey-text text-darken-3 active">E-mailadres</label>
                    <textarea id='email' className="materialize-textarea" onChange={e => contact.email = e.target.value} defaultValue={contact.email} />
                </div>
                <br/>
                <div className="input-field">
                    <label htmlFor='facebook'className="grey-text text-darken-3 active">Facebook pagina</label>
                    <textarea id='facebook' className="materialize-textarea" onChange={e => contact.facebook = e.target.value} defaultValue={contact.facebook} />
                </div>
                <br/>
                <div className="input-field">
                    <button className="btn blue darken-4 z-depth-0" id="opslaanButton">Opslaan</button>
                </div>
            </form>}
        </div>
    )
}

export default ContactSettings;