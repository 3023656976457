import React from 'react';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Select } from 'react-materialize';

const SignUp = (props) => {
    const newUser = {
        email: '',
        password: '',
        realName: '',
        userName: '',
        role: ''
    }
    const handleChange = (e) => {
        newUser[e.target.id] = e.target.value;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        createNewUser(newUser);
    }
    const createNewUser = (newUser) => {
        const createUser = firebase.functions().httpsCallable('createUser');
        var addButton = document.getElementById("addButton");
        addButton.value = "Opslaan...";
        addButton.classList.add("disabled");
        
        createUser(newUser).then((resp) => {
            console.log(resp.data.result);
            addButton.value = "Voeg nieuwe gebruiker toe";
            addButton.classList.remove("disabled");
            resetForm();
        }).catch((err) => {
            console.log(err.message);
        });
    }
    const resetForm = () => {
        document.getElementById("newUserForm").reset();
        document.getElementById("role").selectedIndex = "0";
    }
    const { authError } = props;
    
    return (
        <div>
            <form onSubmit={handleSubmit} id="newUserForm" style={{marginTop:"0"}}>
                <div className="input-field">
                    <label htmlFor="realName" className="grey-text text-darken-3">Echte Naam <sup className="red-text bold-text">*</sup></label>
                    <input type="text" id="realName" onChange={handleChange} required />
                </div>
                <div className="input-field">
                    <label htmlFor="userName" className="grey-text text-darken-3">Gebruikers Naam <sup className="red-text bold-text">*</sup></label>
                    <input type="text" id="userName" onChange={handleChange} required />
                </div>
                <div className="input-field">
                    <label htmlFor="email" className="grey-text text-darken-3">Email <sup className="red-text bold-text">*</sup></label>
                    <input type="email" id="email" onChange={handleChange} required />
                </div>
                <div className="input-field">
                    <label htmlFor="password" className="grey-text text-darken-3">Password <sup className="red-text bold-text">*</sup></label>
                    <input type="password" id="password" onChange={handleChange}  required />
                </div>
                <Select id="role" value="" required onChange={handleChange} label="Gebruikers Rol"/*{<sup className='red-text bold-text'>Gebruikers rol</sup>}*/>
                    <option disabled value="">Kies een rol</option>
                    <option value="admin">Admin</option>
                    <option value="leraar">Leraar</option>
                    <option value="ouders">Ouders</option>
                </Select>
                <div className="input-field">
                    <input type="submit" id="addButton" value="Voeg nieuwe gebruiker toe" className="btn blue darken-4 z-depth-0" />
                    <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                    <div className="red-text center">
                        { authError ? <p>{authError}</p> : null }
                    </div>
                </div>                
            </form>
        </div>
    )
}

export default SignUp;