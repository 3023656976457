import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import Vacature from './Vacature';

function Vacatures(props) {
    const { vacatures } = props;
    
    useEffect(() => {
        document.title = `Vacatures`;
    });

    if(vacatures && vacatures.length > 0) {
        return (
            <div className="container">
            <br />
                <h4>Vacatures</h4>
                <div>
                    <p>Hieronder vindt u onze huidige openstaande vacatures. 
                    Bent u geïnteresseerd stuur dan voor de verloop datum een mail met uw sollicitatiebrief en CV 
                    naar: directie@hetkleurenorkest.com of bel naar +597 7410188 voor meer informatie.</p>
                </div>
                <hr />
                <div>
                    {vacatures && vacatures.map(vacature => {
                        return(
                            <Vacature vacature={vacature} key={vacature.id} />
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return (
            <div className="container">
            <br />
                <h4>Vacatures</h4>
                <div>
                    <p>Hieronder vindt u onze huidige openstaande vacatures. 
                    Bent u geïnteresseerd stuur dan voor de verloop datum een mail met uw sollicitatiebrief en CV 
                    naar: directie@hetkleurenorkest.com of bel naar +597 7410188 voor meer informatie.</p>
                </div>
                <hr />
                <div className="card green lighten-4">
                    <div className="card-content">
                    Op dit moment zijn er geen openstaande vacatures. U kunt altijd een open sollicitatie sturen, of later nog eens kijken.
                    </div>                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        vacatures: state.firestore.ordered.vacatures
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'vacatures', orderBy: ['einddatum'] }
    ])
)(Vacatures);