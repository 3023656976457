import { DateTime } from 'luxon';

function Vacature(props){
    const { vacature } = props;

    const ToggleReadMore = () => {
        document.getElementById(`vacatureTekst_${vacature.id}`).classList.toggle("line-clamp");
        var b = document.getElementById(`readMoreButton_${vacature.id}`);
        b.innerHTML = b.innerHTML === "Lees Meer" ? "Lees Minder" : "Lees Meer";
    }

    return(
        <div className="card yellow lighten-4">
            <div className="card-content">
                <span className="card-title"><b>{vacature.titel}</b></span>
                <p className="grey-text">Verloopt op {DateTime.fromJSDate(vacature.einddatum.toDate()).toLocaleString()}</p>
                <p className="line-clamp text-formatting" id={`vacatureTekst_${vacature.id}`}>{`${vacature.tekst}`}</p>
            </div>
            <div className="card-action">
                <button className="btn z-depth-0" id={`readMoreButton_${vacature.id}`} onClick={ToggleReadMore}>Lees Meer</button>
            </div>
        </div>
    )
}

export default Vacature;