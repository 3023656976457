import React, {useState, useEffect}  from 'react';
import firebase from 'firebase/app';

const db = firebase.firestore();

function Kalender() {
    const [downloadUrl, setDownloadUrl] = useState('');
    
    const fetchDownloadUrl = async () => {
        const downloadUrl = await db.collection("fileLinks").doc("kalender").get();
        setDownloadUrl(downloadUrl.data().url);
    }
    fetchDownloadUrl();
    
    useEffect(() => {
        document.title = `Kalender`;
    });

    return (
        <div className="container">
            <br />
            <h4>Kalender</h4>
            <hr />
            <iframe title='kalender' src={downloadUrl ? downloadUrl : ""} width="100%" height="1000px">
            </iframe>
        </div>
    )
}

export default Kalender