import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { DateTime } from 'luxon';
import CreateFotoAlbum from './CreateFotoAlbum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';

const db = firebase.firestore();

function FotoAlbums (props) {
    const { auth, profile, fotoAlbums } = props;
    
    useEffect(() => {
        document.title = `Foto Albums`;
    });

    if( !auth.uid) return <Redirect to='/' />

    const deleteAlbum = async (album) => {
        const albumDoc= await db.collection("fotoAlbums").doc(album.id).get();
        if(albumDoc.data().fotoUrls){
            const fotos = albumDoc.data().fotoUrls;
            await fotos.forEach(foto => {
                const fotoRef = firebase.storage().ref().child(`fotos/albums/${album.albumName}/${foto.name}`);
                fotoRef.delete(); 
            });                     
            db.collection("fotoAlbums").doc(album.id).delete();          
        } else {
            db.collection("fotoAlbums").doc(album.id).delete();
        }      
    }

    return (
        <div className="container">
        <br />
            {profile && profile.role === 'admin' ? <CreateFotoAlbum /> : null}
            <h4>Foto Albums</h4>
            <hr />
            <div className="row">
                {fotoAlbums && fotoAlbums.map(album => {
                    return (                        
                        <div className="card col s12 m6 l4 orange lighten-4">
                            {album.fotoUrls.length > 0 ? <div className="card-image" style={{marginTop:"0.75rem"}}><a href={`/fotoalbums/show/${album.id}`}><img src={album.fotoUrls[0].url} alt="Album Foto" /></a></div> : null}
                            <div className="card-title"><a href={`/fotoalbums/show/${album.id}`} className="black-text">{album.albumName}</a></div>
                            <div className="grey-text" style={{margin:"0.75rem"}}>{DateTime.fromJSDate(album.createdAt.toDate()).toLocaleString(DateTime.DATETIME_MED)}</div>
                            <div className="card-action">
                                <button className="btn z-depth-0 red accent-4" id={`deleteButton_${album.id}`} onClick={() => deleteAlbum(album)} style={{marginLeft:20}}><FontAwesomeIcon icon={faTrashAlt}/></button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    ) 
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        fotoAlbums: state.firestore.ordered.fotoAlbums
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'fotoAlbums', orderBy: ['createdAt', 'desc'] }
    ])
)(FotoAlbums);