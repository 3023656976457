import authReducer from './authReducer';
import teamReducer from './teamReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import vacatureReducer from './vacatureReducer';
import groepReducer from './groepReducer';
import groepsBerichtReducer from './groepsBerichtReducer';
import fotoAlbumReducer from './fotoAlbumReducer';
import nieuwsReducer from './nieuwsReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    team: teamReducer,
    vacatures: vacatureReducer,
    groepen: groepReducer,
    groepsBerichten: groepsBerichtReducer,
    fotoAlbums: fotoAlbumReducer,
    nieuws: nieuwsReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
});

export default rootReducer;