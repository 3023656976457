import React, {useState, useEffect}  from 'react';
import firebase from 'firebase/app';
import BestuurList from '../Team/BestuurList';

function Bestuur() {
    const [settings, setSettings] = useState(null);

    const fetchSettings = async () => {
        const settingsDoc = await firebase.firestore().collection('settings').doc('teksten').get();
        setSettings(settingsDoc.data());
    }
    if(!settings){
        fetchSettings();
    }
    
    useEffect(() => {
        document.title = `Het Bestuur`;
    });

    return (
        <div className="container">
            <br/>
            <h4>Bestuur</h4>
            <hr />
            {!settings ? "Tekst laden..." :
            <p className="text-formatting">{`${settings.bestuur}`}</p>}
            
            <h6>Bestuursleden</h6>
            <BestuurList/>
            <br/>
            <p>
            Het bestuur is te bereiken via:<br/>
            Naam: 	Stichting Nederlandse basisschool "Het Kleurenorkest"<br/>
            Adres:		Veldhuizenlaan 63<br/>
            Plaats:		Paramaribo, Suriname<br/>
            Telefoon:	(+597) 432120<br/>
            Emailadres: <a href="mailto:bestuur@hetkleurenorkest.com">bestuur@hetkleurenorkest.com</a>  
            </p>              
            <br/>
        </div>
    )
}

export default Bestuur
