import React from 'react';
import { Button, Modal } from 'react-materialize';
import { connect } from 'react-redux';
import { addNieuws } from '../../store/actions/nieuwsActions';
import { Redirect } from 'react-router-dom';
import firebase from '../../config/fbConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import M from "materialize-css";

function CreateNieuws(props) {
    const { auth } = props;
    var newNieuws = {
        titel: '',
        foto: '',
        fotoName: '',
        tekst: '',
        createdAt: new Date(),
        imageFile: null
    }

    const resetForm = () => {
        document.getElementById("nieuwsForm").reset();
        newNieuws = {
            titel: '',
            foto: '',
            fotoName: '',
            tekst: '',
            createdAt: new Date(),
            imageFile: null
        }

        var inputs = document.forms["nieuwsForm"].getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = false;            
        }
        inputs = document.forms["nieuwsForm"].getElementsByTagName('textarea');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = false;            
        }
        document.getElementById("nieuwsButton").classList.remove("disabled");
        document.getElementById("fotoButton").classList.remove("disabled");
    }

    const handleFileChange = async (e) => {
        if(e.target.files[0]){
            const image = e.target.files[0];
            newNieuws.imageFile = image;
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        var modalElem = document.getElementById("newNieuwsModal");
        var instance = M.Modal.getInstance(modalElem);
        var inputs = document.forms["nieuwsForm"].getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = true;            
        }
        inputs = document.forms["nieuwsForm"].getElementsByTagName('textarea');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = true;            
        }
        document.getElementById("nieuwsButton").classList.add("disabled");
        document.getElementById("fotoButton").classList.add("disabled");

        if(newNieuws.imageFile){
            const uploadTask = firebase.storage().ref(`nieuws/${newNieuws.imageFile.name}`).put(newNieuws.imageFile);
            uploadTask.on('state_changed', 
            (snapshot) => {}, 
            (error) => { console.log(error); }, 
            () => {
                firebase.storage().ref('nieuws').child(newNieuws.imageFile.name).getDownloadURL().then((fotoUrl) => {
                    newNieuws.foto = fotoUrl;
                    newNieuws.fotoName = newNieuws.imageFile.name;
                    props.addNieuws({
                        titel: newNieuws.titel,
                        foto: newNieuws.foto, 
                        fotoName: newNieuws.fotoName,
                        tekst: newNieuws.tekst, 
                        createdAt: new Date()
                    });
                    instance.close();               
                });
            });  
        } else {
            props.addNieuws({
                titel: newNieuws.titel,
                foto: newNieuws.foto, 
                fotoName: newNieuws.fotoName,
                tekst: newNieuws.tekst, 
                createdAt: new Date()
            });
            instance.close();
        }    
    }
    if( !auth.uid) return <Redirect to='/' />
    return (
        <div>
            <Modal
                actions={[<Button flat modal="close" node="button">Annuleer</Button>]}
                header="Nieuws Plaatsen"
                id="newNieuwsModal"
                open={false}
                trigger={<Button className="btn-floating right blue darken-4 z-depth-0"><FontAwesomeIcon icon={faNewspaper} size="lg"/></Button>}
                options={{
                    onCloseStart: () => {resetForm()}
                }}
            >
                <form onSubmit={handleSubmit} id="nieuwsForm" style={{marginTop:"0"}}>
                    <div className="input-field">
                        <label htmlFor="titelNieuws" className="grey-text text-darken-3">Titel</label>
                        <input type="text" id="titelNieuws" onChange={e => newNieuws.titel = e.target.value} required />
                    </div>
                    <div class="file-field input-field">
                        <div class="btn blue darken-2 z-depth-0" id="fotoButton">
                            <span htmlFor="fotoNieuws">Foto</span>
                            <input type="file" id="fotoNieuws" accept="image/*" onChange={handleFileChange} />
                        </div>
                        <div class="file-path-wrapper">
                            <input class="file-path" type="text"/>
                        </div>
                    </div>
                    <div className="input-field">
                        <label htmlFor="tekst" className="grey-text text-darken-3">Tekst</label>
                        <textarea type="text" id="tekst" className="materialize-textarea" onChange={e => newNieuws.tekst = e.target.value} required />
                    </div>
                    <div className="input-field">
                        <button className="btn blue darken-4 z-depth-0" id="nieuwsButton">Plaatsen</button>
                        <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNieuws: (nieuws) => dispatch(addNieuws(nieuws))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNieuws);