import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import firebase from 'firebase/app';
import GroepsBericht from './GroepsBericht';
import CreateGroepsBericht from './CreateGroepsBericht';
import EditGroep from './EditGroep';

const db = firebase.firestore();

function Groep(props) {
    const { id } = useParams();
    const { profile } = props;

    const [groepId, setGroepId] = useState('');
    const [groep, setGroep] = useState(null);
    if(id !== groepId){
        setGroepId(id);
        setGroep(null);
    }

    const fetchGroep = async () => {
        const groepDoc = await db.collection("groepen").doc(id).get();
        setGroep(groepDoc.data());
    }
    if(!groep){
        fetchGroep();
    }
    
    useEffect(() => {
        if(groep){
        document.title = `${groep.naam}`;}
    });
    
    return (
        <div className="container">
            {!groep ? "Groeps informatie laden..." :
            <div>
                <br />
                <div className="groep-header">
                    <img src={groep.fotoUrl} alt="lokaal foto" className="groep-foto"/>
                    <div className="overlay">
                        <h4 className="white-text bottom-left">{groep.naam}</h4>
                        <h6 className="bottom-right">Leraar: {groep.leraar}</h6>
                        {profile && (profile.role === 'admin' || profile.role === 'leraar') ? 
                            <div>
                                <EditGroep groep={groep} />
                                <CreateGroepsBericht groepId={id} /> 
                            </div>
                        : null}
                    </div>                                   
                </div>
                <div className="card">
                    <div className="card-content">{groep.introTekst}</div>
                </div>
                <div className="row">
                    {groep.berichten && groep.berichten.map(bericht => {
                        return(
                            <GroepsBericht bericht={bericht} groepId={id} key={bericht.titel}/>
                        )
                    })}
                </div>               
            </div>}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Groep);