import React from 'react';
import { Button, Modal } from 'react-materialize';
import { connect } from 'react-redux';
import { addFotoAlbum } from '../../store/actions/fotoAlbumActions';
import firebase from '../../config/fbConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import M from "materialize-css";

function CreateFotoAlbum(props) {
    var newFotoAlbum = {
        albumName: '',
        fotoUrls: [],
        createdAt: new Date(),
        imageFiles: []
    }

    const resetForm = () => {
        document.getElementById("fotoAlbumForm").reset();
        newFotoAlbum = {
            titel: '',
            fotoUrls: [],
            createdAt: new Date(),
            imageFiles: []
        }

        var inputs = document.forms["fotoAlbumForm"].getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = false;            
        }
        inputs = document.forms["fotoAlbumForm"].getElementsByTagName('textarea');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = false;            
        }
        document.getElementById("fotoAlbumButton").classList.remove("disabled");
        document.getElementById("fotoButton").classList.remove("disabled");
    }

    const handleFileChange = async (e) => {
        if(e.target.files[0]){
            newFotoAlbum.imageFiles = [...e.target.files];
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        var modalElem = document.getElementById("newFotoAlbumModal");
        var instance = M.Modal.getInstance(modalElem);
        var inputs = document.forms["fotoAlbumForm"].getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = true;            
        }
        inputs = document.forms["fotoAlbumForm"].getElementsByTagName('textarea');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = true;            
        }
        document.getElementById("fotoAlbumButton").classList.add("disabled");
        document.getElementById("fotoButton").classList.add("disabled");

        if(newFotoAlbum.imageFiles){
            const storageRef = firebase.storage().ref();
            const fileUploads = newFotoAlbum.imageFiles.map(file => storageRef.child(`fotos/albums/${newFotoAlbum.albumName}/${file.name}`).put(file));
            
            const files = (await Promise.all(fileUploads));
            const fileNames = files.map(f => f._delegate.metadata.name);
            const fileURLReqs = files.map(f => f.ref.getDownloadURL());
            const fileUrls = await Promise.all(fileURLReqs);

            const fileObjects = fileNames.map((f,i) => ({
                name: f,
                url: fileUrls[i]
            }));

            newFotoAlbum.fotoUrls = fileObjects;
            props.addFotoAlbum({
                albumName: newFotoAlbum.albumName,
                fotoUrls: newFotoAlbum.fotoUrls,
                createdAt: new Date()
            })
            instance.close();
        } else {
            props.addFotoAlbum({
                albumName: newFotoAlbum.albumName,
                fotoUrls: [],
                createdAt: new Date()
            });
            instance.close();
        }    
    }
    
    return (
        <div>
            <Modal
                actions={[<Button flat modal="close" node="button">Annuleer</Button>]}
                header="Foto Album Maken"
                id="newFotoAlbumModal"
                open={false}
                trigger={<Button className="btn-floating right blue darken-4 z-depth-0"><FontAwesomeIcon icon={faNewspaper} size="lg"/></Button>}
                options={{
                    onCloseStart: () => {resetForm()}
                }}
            >
                <form onSubmit={handleSubmit} id="fotoAlbumForm" style={{marginTop:"0"}}>
                    <div className="input-field">
                        <label htmlFor="albumName" className="grey-text text-darken-3">Album Naam</label>
                        <input type="text" id="albumName" onChange={e => newFotoAlbum.albumName = e.target.value} required />
                    </div>
                    <div class="file-field input-field">
                        <div class="btn blue darken-2 z-depth-0" id="fotoButton">
                            <span htmlFor="fotos">Foto's</span>
                            <input type="file" id="fotos" accept="image/*" multiple onChange={handleFileChange} />
                        </div>
                        <div class="file-path-wrapper">
                            <input class="file-path" type="text"/>
                        </div>
                    </div>
                    <div className="input-field">
                        <button className="btn blue darken-4 z-depth-0" id="fotoAlbumButton">Maken</button>
                        <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addFotoAlbum: (fotoAlbum) => dispatch(addFotoAlbum(fotoAlbum))
    }
}

export default connect(null, mapDispatchToProps)(CreateFotoAlbum);