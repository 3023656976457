import React from 'react';
import { connect } from 'react-redux';
import { addTeamMember } from '../../store/actions/teamActions';
import { Redirect } from 'react-router-dom';
import firebase from '../../config/fbConfig';

function CreateTeamMember(props) {
    const { auth } = props;
    var newMember = {
        naam: '',
        foto: '',
        fotoName: '',
        functie: '',
        over: '',
        bestuur: false,
        imageFile: null
    }

    const resetForm = () => {
        document.getElementById("memberForm").reset();
        newMember = {
            naam: '',
            foto: '',
            fotoName: '',
            functie: '',
            over: '',
            bestuur: false,
            imageFile: null
        }

        var inputs = document.forms["memberForm"].getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = false;            
        }
        inputs = document.forms["memberForm"].getElementsByTagName('textarea');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = false;            
        }
        document.getElementById("memberButton").classList.remove("disabled");
        document.getElementById("fotoButton").classList.remove("disabled");
    }

    const handleFileChange = async (e) => {
        if(e.target.files[0]){
            const image = e.target.files[0];
            newMember.imageFile = image;
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        var inputs = document.forms["memberForm"].getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = true;            
        }
        inputs = document.forms["memberForm"].getElementsByTagName('textarea');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = true;            
        }
        document.getElementById("memberButton").classList.add("disabled");
        document.getElementById("fotoButton").classList.add("disabled");

        if(newMember.imageFile){
            const uploadTask = firebase.storage().ref(`team/${newMember.imageFile.name}`).put(newMember.imageFile);
            uploadTask.on('state_changed', 
            (snapshot) => {}, 
            (error) => { console.log(error); }, 
            () => {
                firebase.storage().ref('team').child(newMember.imageFile.name).getDownloadURL().then((fotoUrl) => {
                    newMember.foto = fotoUrl;
                    newMember.fotoName = newMember.imageFile.name;
                    props.addTeamMember({
                        naam: newMember.naam,
                        foto: newMember.foto, 
                        fotoName: newMember.fotoName,
                        functie: newMember.functie, 
                        over: newMember.over,
                        bestuur: newMember.bestuur
                    });
                    resetForm();                
                });
            });  
        } else {
            props.addTeamMember({
                naam: newMember.naam,
                foto: newMember.foto, 
                fotoName: newMember.fotoName,
                functie: newMember.functie, 
                over: newMember.over,
                bestuur: newMember.bestuur
            });
            resetForm();
        }    
    }
    if( !auth.uid) return <Redirect to='/' />
    return (
        <div>
            <form onSubmit={handleSubmit} id="memberForm" style={{marginTop:"0"}}>
                <div className="input-field">
                    <label htmlFor="naamTeamMember" className="grey-text text-darken-3">Naam</label>
                    <input type="text" id="naamTeamMember" onChange={e => newMember.naam = e.target.value /*setNaam(e.target.value)*/} required />
                </div>
                <div className="file-field input-field">
                    <div className="btn blue darken-2 z-depth-0" id="fotoButton">
                        <span htmlFor="fotoTeamMemberCreate">Foto</span>
                        <input type="file" id="fotoTeamMemberCreate" accept="image/*" onChange={handleFileChange} />
                    </div>
                    <div className="file-path-wrapper">
                        <input className="file-path" type="text"/>
                    </div>
                </div>
                <div className="input-field">
                    <label htmlFor="functie" className="grey-text text-darken-3">Functie</label>
                    <input type="text" id="functie" onChange={e => newMember.functie = e.target.value /*setFunctie(e.target.value)*/} required />
                </div>
                <div className="input-field">
                    <label htmlFor="over" className="grey-text text-darken-3">Over mij</label>
                    <textarea id="over" className="materialize-textarea" onChange={e => newMember.over = e.target.value /*setOver(e.target.value)*/} />
                </div>
                <p>
                    <label htmlFor="bestuur" className="grey-text text-darken-3">
                        <input type="checkbox" id="bestuur" onChange={e => newMember.bestuur = e.target.checked} />
                        <span>Bestuurslid</span>
                    </label>
                </p>
                <div className="input-field">
                    <button className="btn blue darken-4 z-depth-0" id="memberButton">Toevoegen</button>
                    <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addTeamMember: (teamMember) => dispatch(addTeamMember(teamMember))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeamMember);