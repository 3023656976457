import React, {useState, useEffect} from 'react';
import { Button } from 'react-materialize';
import firebase from '../../config/fbConfig';

const db = firebase.firestore();

function ChangeBackground() {
    const uploadButton = document.getElementById("uploadAchtergrondButton");

    const [achtergrondFile, setAchtergrondFile] = useState(null);

    var finishedUploading = async () => {
        document.getElementById("uploadAchtergrondForm").reset();

        uploadButton.innerText = "Vervang Achtergrond";
        uploadButton.classList.remove("disabled");

        const oldFile = await db.collection("fileLinks").doc("achtergrond").get();
        const oldFileRef = firebase.storage().ref().child(`header/${oldFile.data().filename}`);
        const URL = await firebase.storage().ref().child(`header/${achtergrondFile.name}`).getDownloadURL();
        oldFileRef.delete().then(function() {       
            db.collection("fileLinks").doc("achtergrond").set({
                filename: achtergrondFile.name,
                url: URL
            });
        }).catch(function(error) {
            console.log(error);
        });
    }
    var uploadStatus = function(snapshot) {
        var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        uploadButton.innerText = `Uploaden: ${percentage.toFixed(2)}%`;
    }
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file !== null) console.log(file);
        setAchtergrondFile(file);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        uploadButton.innerText = "Uploaden...";
        uploadButton.classList.add("disabled");

        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`header/${achtergrondFile.name}`);
        const uploadTask = fileRef.put(achtergrondFile);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        {
            'next': uploadStatus,
            'complete': finishedUploading
        });        
    }

    useEffect(() => {
        if(uploadButton !== null) {
            if(achtergrondFile) {
                uploadButton.classList.remove("disabled");
            } else {
                uploadButton.classList.add("disabled");
            }
        }
    })

    return (
        <div>
            <form id="uploadAchtergrondForm" onSubmit={handleSubmit} style={{marginTop:"0", padding:"0"}}>
                <input type="file" accept="image/*" onChange={handleFileChange} />
                <Button id="uploadAchtergrondButton" className="orange darken-2 disabled" style={{marginLeft:"16px"}}>Vervang Achtergrond</Button>
            </form>
        </div>
    )
}

export default ChangeBackground;