import React from 'react';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Button, Modal } from 'react-materialize';
import M from "materialize-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';

const db = firebase.firestore();

const EditGroepsBericht = ({bericht, groepId}) => {
    const editGroepsBericht = {
        id: bericht.id,
        titelEdit: bericht.titel,
        fotoUrlEdit: bericht.fotoUrl,
        fotoNameEdit: bericht.fotoName,
        tekstEdit: bericht.tekst,
        imageFile: null
    }    
    var berichtenUpdate = [];
    var berichtIndex = -1;

    const fetchGroepsBerichten = async() => {
        const groepDoc = await db.collection("groepen").doc(groepId).get();
        berichtenUpdate = groepDoc.data().berichten;
        berichtIndex = berichtenUpdate.findIndex(e => {
            return e.id === editGroepsBericht.id;
        });
    }
    fetchGroepsBerichten();    
    
    const handleFileChange = async (e) => {
        if(e.target.files[0]){
            const image = e.target.files[0];
            editGroepsBericht.imageFile = image;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        editGroepsBerichtFunc(editGroepsBericht);
    }

    const UpdateBericht = (editedGroepsBericht) => {
        var saveButton = document.getElementById(`saveButton-${editedGroepsBericht.id}`);
        saveButton.value = "Opslaan...";
        saveButton.classList.add("disabled");
        var modalElem = document.getElementById(`bewerk-modal-${editedGroepsBericht.id}`)
        var instance = M.Modal.getInstance(modalElem);

        const index = berichtIndex;
        berichtenUpdate[index].titel = editedGroepsBericht.titelEdit;
        berichtenUpdate[index].fotoUrl = editedGroepsBericht.fotoUrlEdit;
        berichtenUpdate[index].fotoName = editedGroepsBericht.fotoNameEdit;
        berichtenUpdate[index].tekst = editedGroepsBericht.tekstEdit;

        db.collection("groepen").doc(groepId).update({
            berichten: berichtenUpdate
        }).then(() => {
            saveButton.value = "Verandering opslaan";
            saveButton.classList.remove("disabled");
            instance.close();
            resetForm();
        }).catch((err) => {
            console.log(err.message);
        });
    }

    const editGroepsBerichtFunc = (editedGroepsBericht) => {
        var saveButton = document.getElementById(`saveButton-${editedGroepsBericht.id}`);
        saveButton.value = "Opslaan...";
        saveButton.classList.add("disabled");

        if(editedGroepsBericht.imageFile){
            const uploadTask = firebase.storage().ref(`groepen/berichten/${editedGroepsBericht.imageFile.name}`).put(editedGroepsBericht.imageFile);
            uploadTask.on('state_changed', 
            (snapshot) => {}, 
            (error) => { console.log(error); }, 
            () => {
                firebase.storage().ref('groepen/berichten').child(editedGroepsBericht.imageFile.name).getDownloadURL().then((fotoUrl) => {
                    if(editedGroepsBericht.fotoNameEdit){
                        const groepsBerichtFotoRef = firebase.storage().ref().child(`groepen/berichten/${editedGroepsBericht.fotoNameEdit}`);
                        groepsBerichtFotoRef.delete().catch(function(error) {
                            console.log(error);
                        });
                    }
                    editedGroepsBericht.fotoUrlEdit = fotoUrl;
                    editedGroepsBericht.fotoNameEdit = editedGroepsBericht.imageFile.name;

                    UpdateBericht(editedGroepsBericht);            
                });
            });
        } else {
            UpdateBericht(editedGroepsBericht); 
        }        
    }

    const resetForm = () => {
        document.getElementById(`editGroepsBerichtForm-${editGroepsBericht.id}`).reset();
        document.getElementById(`titelEditLabel-${editGroepsBericht.id}`).classList.add("active");
        document.getElementById(`tekstEditLabel-${editGroepsBericht.id}`).classList.add("active");
        editGroepsBericht.imageFile = null;
    }
    
    return (
        <Modal
            actions={[<Button flat modal="close" node="button">Annuleer</Button>]}
            header={`Bewerk bericht: ${bericht.titel}`}
            id={`bewerk-modal-${editGroepsBericht.id}`}
            open={false}
            trigger={<Button className="flat orange darken-2 z-depth-0"><FontAwesomeIcon icon={faUserEdit}/></Button>}
        >
            <form onSubmit={handleSubmit} id={`editGroepsBerichtForm-${editGroepsBericht.id}`} style={{marginTop:"0"}}>
                <div className="input-field">
                    <label htmlFor={`titelEdit-${editGroepsBericht.id}`} id={`titelEditLabel-${editGroepsBericht.id}`} className="grey-text text-darken-3 active">Titel</label>
                    <input type="text" id={`titelEdit-${editGroepsBericht.id}`} onChange={e => editGroepsBericht.titelEdit = e.target.value} defaultValue={editGroepsBericht.titelEdit} required />
                </div>
                <div className="file-field input-field">
                    <div className="btn blue darken-2 z-depth-0" id="fotoButton">
                        <span htmlFor="fotoGroepsBerichtEdit">Foto</span>
                        <input type="file" id="fotoGroepsBerichtEdit" accept="image/*" onChange={handleFileChange} />
                    </div>
                    <div className="file-path-wrapper">
                        <input className="file-path" type="text" defaultValue={editGroepsBericht.fotoNameEdit}/>
                    </div>
                </div>
                <div className="input-field">
                    <label htmlFor={`tekstEdit-${editGroepsBericht.id}`} id={`tekstEditLabel-${editGroepsBericht.id}`} className="grey-text text-darken-3 active">Tekst</label>
                    <textarea id={`tekstEdit-${editGroepsBericht.id}`} className="materialize-textarea" onChange={e => editGroepsBericht.tekstEdit = e.target.value} defaultValue={editGroepsBericht.tekstEdit} />
                </div>
                <div className="input-field">
                    <input type="submit" value="Veranderingen opslaan" className="btn blue darken-4 z-depth-0" id={`saveButton-${editGroepsBericht.id}`} />
                    <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                </div>                
            </form>
        </Modal>
    )
}

export default EditGroepsBericht;