import firebase from '../../config/fbConfig';
import Navbar from './Navbar';
import Banner from './Banner';
import { useState, useEffect } from 'react';

const db = firebase.firestore();

const Header = () => {
    const [headerPhotos, setHeaderPhotos] = useState(null);
    const [headerBackground, setHeaderBackground] = useState(null);
    const [downloadUrl, setDownloadUrl] = useState('');

    useEffect(() => {
        const fetchDownloadUrl = async () => {
            const downloadUrl = await db.collection("fileLinks").doc("achtergrond").get();
            setDownloadUrl(downloadUrl.data().url);
        }
        fetchDownloadUrl();
    })

    const colors = ['#da0000', '#ff7f00', '#f2ff1b', '#80ff00', '#4dd8ff', '#000df2', '#7f00ff'];

    const fetchHeaderPhotos = async () => {
        const albumDoc = await db.collection("fileLinks").doc("headerPhotos").get();
        var photoArray = albumDoc.data().photoUrls;
        var shuffledArray = shuffleArray(photoArray);
        setHeaderPhotos(shuffledArray);
    }
    if(!headerPhotos){
        fetchHeaderPhotos();
    }

    const shuffleArray = (array) => {
        var slicedArray = [];
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return slicedArray = array.slice(0,3);
    }
    
    return(
        <div className="header">
            <div className='hero-image' style={{backgroundImage:`url(${downloadUrl ? downloadUrl : "./img/background2.jpg"})`}}>  
                <Navbar />
                {headerPhotos && headerPhotos.length > 0 ?
                <div className='header-logo-1 col s6 m4 l2 offset-s3 offset-m2 offset-l4' style={{borderColor:colors[Math.floor(Math.random()*colors.length)]}}><img src={headerPhotos[0].url} alt="Header Foto"/></div> 
                : null} 
            </div>         
            <div className="center-align white-text red accent-4 shadow">
                <h5 className="slogan white-text" style={{fontWeight:"normal"}}>“Hier bouwen wij samen aan de basis van de toekomst van onze kinderen.”</h5>
            </div>
            <Banner/>
        </div>
    )
}

export default Header;