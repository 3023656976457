import React, { useEffect } from 'react';
import { Carousel } from 'react-materialize';
import Schoolgids from '../Schoolgids/Schoolgids';

function School() {
    useEffect(() => {
        document.title = `De School`;
    });

    return (
        <div className="container">
            <br/>
            <div>
                <Schoolgids extra={"right hide-on-small-only"} />
                <h4>Onze School</h4>
                <Schoolgids extra={"hide-on-med-and-up"} />
                <hr />
            </div>
            <div className="card">
                <div className="card-content">
                <h5>De naam “Het Kleurenorkest”</h5>
                <p>De naam Het Kleurenorkest is zo gekozen omdat de oprichters van onze school van mening zijn dat de school toegankelijk moet zijn voor alle kinderen, ongeacht geboorteplaats, afkomst en religie. De school staat voor: een stabiele, veilige omgeving waar kwalitatief goed onderwijs wordt aangeboden zodat kleurrijke kinderen uit onze kleurrijke samenleving tot bloei kunnen komen. Deze kleurrijke kinderen vormen samen met hun leerkrachten een orkest, waarin door samenspel en respect, gewerkt wordt aan de ontwikkeling van elkaar. Door uit te gaan van de onderwijsbehoeften van ieder kind, lukt het om een orkest te zijn waar een ieder zijn eigen mooie tonen speelt in zijn ontwikkeling naar een goede toekomst.</p> 
                </div>
                <div className="card-image">
                    <Carousel carouselId="carousel-school"
                        images={[
                            "/img/gebouw/gebouw_1.jpg",
                            "/img/gebouw/gebouw_2.jpg",                        
                            "/img/gebouw/gebouw_3.jpg",                        
                            "/img/gebouw/gebouw_4.jpg",                        
                            "/img/gebouw/gebouw_5.jpg",                        
                            "/img/gebouw/gebouw_6.jpg",                        
                            "/img/gebouw/gebouw_7.jpg",                        
                            "/img/gebouw/gebouw_8.jpg",                        
                            "/img/gebouw/gebouw_9.jpg",                        
                            "/img/gebouw/gebouw_10.jpg",                        
                            "/img/gebouw/gebouw_11.jpg",                        
                        ]}
                        options={{
                            duration: 100,
                            fullWidth: true,
                            indicators: true
                        }}
                    />
                </div>
            </div>
            <div className="row card valign-medium">
            <div className="col l4 m6 hide-on-small-only card-content">
                <img className="responsive-img" src="favicon.png" alt="logo"/>
            </div>
            <div className="col l8 m6 s12 card-content">
                <h5>Het logo</h5>
                <p>In ons logo staan de gekleurde balken in het teken van die kleurrijke kinderen. De hoogte van de gekleurde balken staan voor het eigen niveau waarop de kinderen zich ontwikkelen. Naast de ontwikkeling van ieder kind, op het eigen niveau, vinden we het belangrijk dat kinderen plezier hebben op school de vrolijke muzieknoten staan voor dit plezier.</p>
            </div>
            <div className="col s12 hide-on-med-and-up">
                <img className="responsive-img" src="favicon.png" alt="logo"/>
            </div>
            </div>
        </div>
    )
}

export default School
