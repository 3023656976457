import React, {useState, useEffect}  from 'react';
import firebase from 'firebase/app';

function Missie() {
    const [settings, setSettings] = useState(null);

    const fetchSettings = async () => {
        const settingsDoc = await firebase.firestore().collection('settings').doc('teksten').get();
        setSettings(settingsDoc.data());
    }
    if(!settings){
        fetchSettings();
    }
    
    useEffect(() => {
        document.title = `Missie en Visie`;
    });

    return (
        <div className="container row" style={{overflow:"hidden", position:"relative"}}>
            <br/>
            <h4>Missie en Visie</h4>
            <hr />
            <div className="col l6 left">
                <div className="card">
                    <span className="card-title center-align"><h5>Missie</h5></span>
                    <div className="card-content">
                        {!settings ? "Tekst laden..." :
                        <p className="text-formatting">{`${settings.missie}`}</p>}
                    </div>                    
                </div>  
                <div className="card">
                    <div className="card-image"><img src="/img/gebouw/gebouw_10.jpg" alt="schoolgebouw"/></div>
                </div> 
            </div>
            <div className="col l6 right">
            <div className="card">
            <span className="card-title center-align"><h5>Visie</h5></span>
            <div className="card-content">
                {!settings ? "Tekst laden..." :
                <p className="text-formatting">{`${settings.visie}`}</p>}
            </div>
            </div>
            </div>
        </div>
    )
}

export default Missie