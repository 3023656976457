import React, {useEffect} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { Button } from 'react-materialize';
import UploadNieuwsbrief from './UploadNieuwsbrief';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import firebase from '../../config/fbConfig';

const db = firebase.firestore();

function Nieuwsbrieven (props) {
    const { auth, profile, nieuwsbrieven } = props;
    
    useEffect(() => {
        document.title = `Nieuwsbrieven`;
    });
    
    if( !auth.uid) return <Redirect to='/' />
    
    const deleteNieuwsbrief = async (nieuwsbriefId) => {
        const nieuwsbrief = await db.collection("nieuwsbrieven").doc(nieuwsbriefId).get();
        const nieuwsbriefRef = firebase.storage().ref().child(`nieuwsbrieven/${nieuwsbrief.data().title}.pdf`);
        nieuwsbriefRef.delete().then(function() {       
            db.collection("nieuwsbrieven").doc(nieuwsbriefId).delete();
        }).catch(function(error) {
            console.log(error);
        });      
    }

    return (
        <div className="container">
            <div>
            <br />
                {profile.role === 'admin' ? <UploadNieuwsbrief/> : null}
                <h4>Nieuwsbrieven</h4>
                <hr />
                <ul class="collection">
                    {nieuwsbrieven && nieuwsbrieven.map(nieuwsbrief => {
                        return (
                            <li class="collection-item orange lighten-4" key={nieuwsbrief.id} style={{lineHeight:2.5}}>
                                <a href={nieuwsbrief.url} target="_blank" rel="noreferrer" className="black-text">{nieuwsbrief.title}<FontAwesomeIcon icon={faExternalLinkAlt} size="xs" style={{marginLeft:"0.5rem"}}/></a>
                                {profile.role === 'admin' ?                               
                                    <Button className="red accent-4 right" onClick={() => deleteNieuwsbrief(nieuwsbrief.id)}><FontAwesomeIcon icon={faTrashAlt}/></Button>
                                : null}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        nieuwsbrieven: state.firestore.ordered.nieuwsbrieven
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'nieuwsbrieven', orderBy: ['createdAt', 'desc'] }
    ])
)(Nieuwsbrieven);