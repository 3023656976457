import React, {useState} from 'react';
import { Footer } from 'react-materialize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faPhoneSquare, faAt, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';

function FooterComp() {
    const [contact, setContact] = useState(null);

    const fetchContact = async () => {
        const contactDoc = await firebase.firestore().collection('settings').doc('contact').get();
        setContact(contactDoc.data());
    }
    if(!contact){
        fetchContact();
    }

    return (
        <div>
            {!contact ? null :
            <Footer
                className="yellow accent-2 black-text shadow"
                copyrights="© 2021 Het Kleurenorkest  |  Created by BMK Digital"
                links={<ul>
                        <li><p className="black-text valign-wrapper"><FontAwesomeIcon icon={faPhoneSquare} size="2x" className="red-text darken-4" />{`${contact.telefoon}`}</p></li>
                        <li><p><a className="black-text valign-wrapper" href={`https://www.facebook.com/${contact.facebook}/`}><FontAwesomeIcon icon={faFacebookSquare} size="2x" className="red-text darken-4" />/{`${contact.facebook}`}</a></p></li>
                        <li><p><a className="black-text valign-wrapper" href={`mailto:${contact.email}`}><FontAwesomeIcon icon={faAt} size="2x" className="red-text darken-4" />{`${contact.email}`}</a></p></li>
                    </ul>}
            >
                <p className="black-text">
                    <FontAwesomeIcon icon={faMapMarkedAlt} size="2x" className="red-text darken-4" /><br/>
                    {`${contact.naam}`}<br/>
                    {`${contact.adres}`}<br/>
                    {`${contact.plaats}`}
                </p>
            </Footer>}
        </div>
    )
}

export default FooterComp;
