import React, {useState, useEffect}  from 'react';
import firebase from 'firebase/app';

function NOB() {
    const [settings, setSettings] = useState(null);

    const fetchSettings = async () => {
        const settingsDoc = await firebase.firestore().collection('settings').doc('teksten').get();
        setSettings(settingsDoc.data());
    }
    if(!settings){
        fetchSettings();
    }
    
    useEffect(() => {
        document.title = `NOB`;
    });

    return (
        <div className="container">
            <br/>
            <h4>Nederlands Onderwijs in het Buitenland</h4>
            <hr />
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content center-align">
                            <a href="http://www.stichtingnob.nl" target="_blank" rel="noreferrer">
                                <img className="responsive-img" src="/img/logo-NOB-2020.png" alt="NOB Logo" />
                            </a> 
                        </div>
                        <div className="card-content">
                        <h5>De organisatie</h5>
                        {!settings ? "Tekst laden..." :
                        <p className="text-formatting">{`${settings.nob}`}</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row hide-on-small-only">
            <div className="col s12">
                    <div className="card">
                        <div className="card-content center-align">
                            <img className="responsive-img" src="/img/nob_scholenzoeker.png" alt="NOB Logo"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NOB
