const initState = {
    teamMembers: [
        {id: '1', naam: 'Web Master', foto: '', functie: 'webmaster', over: 'building websites'}
    ]
}

const teamReducer = (state = initState, action) => {
    switch (action.type){
        case 'ADD_TEAMMEMBER':
            console.log('added teammember', action.teamMember);
            return state;
        case 'ADD_TEAMMEMBER_ERROR':
            console.log('adding teammember error', action.err);
            return state;
        default:
            return state;
    }
}

export default teamReducer;