import React from 'react';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Button, Modal, Select } from 'react-materialize';
import M from "materialize-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';

const EditUser = ({user}) => {
    const editUser = {
        id: user.id,
        realNameEdit: user.realName,
        userNameEdit: user.userName,
        emailEdit: user.email,
        roleEdit: user.role
    }
    const handleChange = (e) => {
        var cutId = e.target.id.length - (user.id.length+1);
        var targetId = e.target.id.substring(0,cutId);
        editUser[targetId] = e.target.value;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        editUserFunc(editUser);
    }
    const editUserFunc = (editedUser) => {
        const editUserCall = firebase.functions().httpsCallable('editUser');
        var saveButton = document.getElementById(`saveButton-${editUser.id}`);
        saveButton.value = "Opslaan...";
        saveButton.classList.add("disabled");
        var modalElem = document.getElementById(`bewerk-modal-${editUser.id}`)
        var instance = M.Modal.getInstance(modalElem);
        editUserCall(editedUser).then((resp) => {
            saveButton.value = "Verandering opslaan";
            saveButton.classList.remove("disabled");
            instance.close();
            console.log(resp.data.result);
            resetForm();
        }).catch((err) => {
            console.log(err.message);
        });
    }
    const resetForm = () => {
        document.getElementById(`editUserForm-${editUser.id}`).reset();
        document.getElementById(`roleEdit-${editUser.id}`).value = `${editUser.roleEdit}`;
        document.getElementById(`realNameEditLabel-${editUser.id}`).classList.add("active");
        document.getElementById(`userNameEditLabel-${editUser.id}`).classList.add("active");
        document.getElementById(`emailEditLabel-${editUser.id}`).classList.add("active");
    }
    
    return (
        <div>
            <Modal
                actions={[<Button flat modal="close" node="button">Annuleer</Button>]}
                header="Bewerk gebruiker"
                id={`bewerk-modal-${editUser.id}`}
                open={false}
                trigger={<Button className="flat orange darken-2"><FontAwesomeIcon icon={faUserEdit}/></Button>}
            >
                <form onSubmit={handleSubmit} id={`editUserForm-${editUser.id}`} style={{marginTop:"0"}}>
                    <div className="input-field">
                        <label htmlFor={`realNameEdit-${editUser.id}`} id={`realNameEditLabel-${editUser.id}`} className="grey-text text-darken-3 active">Echte Naam <sup className="red-text bold-text">*</sup></label>
                        <input type="text" id={`realNameEdit-${editUser.id}`} onChange={handleChange} defaultValue={editUser.realNameEdit} required />
                    </div>
                    <div className="input-field">
                        <label htmlFor={`userNameEdit-${editUser.id}`} id={`userNameEditLabel-${editUser.id}`} className="grey-text text-darken-3 active">Gebruikers Naam <sup className="red-text bold-text">*</sup></label>
                        <input type="text" id={`userNameEdit-${editUser.id}`} defaultValue={editUser.userNameEdit} onChange={handleChange} required />
                    </div>
                    <div className="input-field">
                        <label htmlFor={`emailEdit-${editUser.id}`} id={`emailEditLabel-${editUser.id}`} className="grey-text text-darken-3 active">Email <sup className="red-text bold-text">*</sup></label>
                        <input type="email" id={`emailEdit-${editUser.id}`} onChange={handleChange} defaultValue={editUser.emailEdit} required />
                    </div>
                    <Select id={`roleEdit-${editUser.id}`} defaultValue={editUser.roleEdit} required onChange={handleChange} label={<sup className='red-text bold-text'>Gebruikers rol</sup>}>
                        <option disabled value="">Kies een rol</option>
                        <option value="admin">Admin</option>
                        <option value="leraar">Leraar</option>
                        <option value="ouders">Ouders</option>
                    </Select>
                    <div className="input-field">
                        <input type="submit" value="Veranderingen opslaan" className="btn blue darken-4 z-depth-0" id={`saveButton-${editUser.id}`} />
                        <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                    </div>                
                </form>
            </Modal>
        </div>
    )
}

export default EditUser;