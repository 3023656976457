import firebase from 'firebase/app';

export const addGroep = (groep) => {
    return (dispatch, { getFirebase, getFirestore }) => {
        const firestore = firebase.firestore();
        firestore.collection('groepen').add({
            ...groep
        }).then(() => {
            dispatch({type: 'ADD_GROEP', groep});
        }).catch((err) => {
            dispatch({type: 'ADD_GROEP_ERROR', err});
        });        
    }
}