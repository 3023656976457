import React, { useState } from 'react';
import { connect } from 'react-redux';
import { addVacature } from '../../store/actions/vacatureActions';
import { Redirect } from 'react-router-dom';
import { DatePicker } from 'react-materialize';

const datePickerOptions = {
    autoClose: true,
    firstDay: 1,
    format: 'dd mmm yyyy',
    i18n: {
        cancel: 'Cancel',
        clear: 'Clear',
        done: 'Ok',
        months: [
            'Januari',
            'Februari',
            'Maart',
            'April',
            'Mei',
            'Juni',
            'Juli',
            'Augustus',
            'September',
            'Oktober',
            'November',
            'December'
        ],
        monthsShort: [
            'Jan',
            'Feb',
            'Maa',
            'Apr',
            'Mei',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Okt',
            'Nov',
            'Dec'
        ],
        nextMonth: '›',
        previousMonth: '‹',
        weekdays: [
            'Zondag',
            'Maandag',
            'Dinsdag',
            'Woensdag',
            'Donderdag',
            'Vrijdag',
            'Zaterdag'
        ],
        weekdaysAbbrev: [
            'Zo',
            'Ma',
            'Di',
            'Wo',
            'Do',
            'Vr',
            'Za'
        ],
        weekdaysShort: [
            'Zon',
            'Maa',
            'Din',
            'Woe',
            'Don',
            'Vrij',
            'Zat'
        ]
    },
    minDate: new Date()
}

function CreateVacature(props){
    const { auth } = props;
    const [titel, setTitel] = useState('');
    const [tekst, setTekst] = useState('');
    const [einddatum, setEinddatum] = useState(new Date());

    const resetForm = () => {
        document.getElementById("vacatureForm").reset();
        setTitel('');
        setTekst('');
        setEinddatum(new Date());

        var inputs = document.forms["vacatureForm"].getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = false;            
        }
        inputs = document.forms["vacatureForm"].getElementsByTagName('textarea');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = false;            
        }
        document.getElementById("vacatureButton").classList.remove("disabled");
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var inputs = document.forms["vacatureForm"].getElementsByTagName('input');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = true;            
        }
        inputs = document.forms["vacatureForm"].getElementsByTagName('textarea');
        for (let index = 0; index < inputs.length; index++) {
            inputs[index].disabled = true;            
        }
        document.getElementById("vacatureButton").classList.add("disabled");

        props.addVacature({
            titel: titel,
            tekst: tekst,
            einddatum: einddatum
        });
        resetForm();
    }

    const handleDateChange = (e) => {
        const date = new Date(e);
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        setEinddatum(date);
    }

    if( !auth.uid) return <Redirect to='/' />
    return(
        <div>
            <form onSubmit={handleSubmit} id="vacatureForm" style={{marginTop:"0"}}>
                <div>
                    <div className="input-field">
                        <label htmlFor="titelVacature" className="grey-text text-darken-3">Titel</label>
                        <input type="text" id="titelVacature" onChange={e => setTitel(e.target.value)} required />
                    </div>
                    <div className="input-field">
                        <label htmlFor="tekst" className="grey-text text-darken-3">Tekst</label>
                        <textarea id="tekst" className="materialize-textarea" onChange={e => setTekst(e.target.value)} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="einddatumVacature" className="grey-text text-darken-3">Einddatum</label>
                        <DatePicker 
                            id="einddatumVacature"
                            options={datePickerOptions} 
                            onChange={handleDateChange}
                            required
                        />
                    </div>
                </div>
                <div className="input-field">
                    <button className="btn blue darken-4 z-depth-0" id="vacatureButton">Toevoegen</button>
                    <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                </div>          
            </form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addVacature: (vacature) => dispatch(addVacature(vacature))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateVacature);