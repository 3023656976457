import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/authActions';

class SignIn extends Component {
    state = {
        email: '',
        password: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state);
    }
    render() {
        const { authError } = this.props;
        return (
            <div>
                <form onSubmit={this.handleSubmit} className="sidenav-form light-blue lighten-2 nav-link-7">
                    <div className="input-field light-blue lighten-3" style={{margin:"5px 0"}}>
                        <label htmlFor="email" className="grey-text text-darken-3">Email</label>
                        <input type="email" id="email" onChange={this.handleChange} className="sidenav-underline" />
                    </div>
                    <div className="input-field light-blue lighten-3" style={{margin:"5px 0"}}>
                        <label htmlFor="password" className="grey-text text-darken-3">Password</label>
                        <input type="password" id="password" onChange={this.handleChange} className="sidenav-underline" />
                    </div>
                    <div className="input-field center-align" style={{margin:"0"}}>
                        <button className="btn orange darken-4 z-depth-0" style={{margin:"0"}}>Login</button>
                        <div className="red-text center">
                            { authError ? <p>{authError}</p> : null }
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);