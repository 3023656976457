const initState = {
    vacatures: [
        {id: '1', titel: 'Web Master', tekst: 'Tekst', einddatum: null}
    ]
}

const vacatureReducer = (state = initState, action) => {
    switch (action.type){
        case 'ADD_VACATURE':
            console.log('added vacature', action.vacature);
            return state;
        case 'ADD_VACATURE_ERROR':
            console.log('adding vacature error', action.err);
            return state;
        default:
            return state;
    }
}

export default vacatureReducer;