import React, {useState} from 'react';
import { Button, Modal } from 'react-materialize';
import firebase from '../../config/fbConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import M from "materialize-css";

const db = firebase.firestore();

function UploadNieuwsbrief (props) {
    const uploadButton = document.getElementById("uploadNieuwsbriefButton");

    const [nieuwsbriefFile, setNieuwsbriefFile] = useState(null);

    var finishedUploading = async () => {
        //reset();
        var modalElem = document.getElementById("UploadNieuwsbriefModal");
        var instance = M.Modal.getInstance(modalElem);

        uploadButton.innerText = "Upload Nieuwsbrief";

        const URL = await firebase.storage().ref().child(`nieuwsbrieven/${nieuwsbriefFile.name}`).getDownloadURL();
        
        await db.collection("nieuwsbrieven").add({
            title: nieuwsbriefFile.name.replace('.pdf', ''),
            url: URL,
            createdAt: new Date()
        });
        instance.close();
    }
    
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file !== null) console.log(file);
        setNieuwsbriefFile(file);
        uploadButton.classList.remove("disabled");
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        uploadButton.innerText = "Uploaden...";
        uploadButton.classList.add("disabled");

        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`nieuwsbrieven/${nieuwsbriefFile.name}`);
        const uploadTask = fileRef.put(nieuwsbriefFile);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        {
            'complete': finishedUploading
        });        
    }

    const reset = () => {
        document.getElementById("uploadNieuwsbriefForm").reset();
        document.getElementById("uploadNieuwsbriefButton").classList.add("disabled");
    }

    return (
        <div>
            <Modal
                actions={[<Button flat modal="close" node="button">Annuleer</Button>]}
                header="Nieuwsbrief Uploaden"
                id="UploadNieuwsbriefModal"
                open={false}
                trigger={<Button className="btn-floating right orange accent-4 z-depth-0"><FontAwesomeIcon icon={faFileAlt} size="lg"/></Button>}
                options={{
                    onCloseStart: () => {reset()}
                }}
            >
                <form id="uploadNieuwsbriefForm" onSubmit={handleSubmit} style={{marginTop:"0", padding:"0"}}>
                    <input type="file" accept=".pdf" onChange={handleFileChange} />
                    <Button id="uploadNieuwsbriefButton" className="orange darken-2 disabled" style={{marginLeft:"16px"}}>Upload Nieuwsbrief</Button>
                </form> 
            </Modal>
        </div>
    ) 
}

export default UploadNieuwsbrief;