import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyChf4yu72ud6cpLoUr1geWyuLgdhIT3OTw",
    authDomain: "het-kleurenorkest.firebaseapp.com",
    projectId: "het-kleurenorkest",
    storageBucket: "het-kleurenorkest.appspot.com",
    messagingSenderId: "456437827743",
    appId: "1:456437827743:web:a6f565d50fff9d37657aaa",
    measurementId: "G-F4E22RWH11"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings({timestampsInSnapshots:true, merge:true});
  firebase.analytics();

  export default firebase;