const initState = {
    nieuws: [
        {id: '1', titel: 'Nieuws', foto: '', fotoName: '', tekst: 'Nieuws Tekst', createdAt: new Date()}
    ]
}

const nieuwsReducer = (state = initState, action) => {
    switch (action.type){
        case 'ADD_NIEUWS':
            console.log('added nieuws', action.nieuws);
            return state;
        case 'ADD_NIEUWS_ERROR':
            console.log('adding nieuws error', action.err);
            return state;
        default:
            return state;
    }
}

export default nieuwsReducer;