import React, {useState} from 'react';
import firebase from '../../config/fbConfig';

function BannerSettings() {
    const [settings, setSettings] = useState(null);
    const [bestand, setBestand] = useState(null);
    
    const opslaanButton = document.getElementById("opslaanButton");

    const fetchSettings = async () => {
        const settingsDoc = await firebase.firestore().collection('settings').doc('banner').get();
        setSettings(settingsDoc.data());
    }
    if(!settings){
        fetchSettings();
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); 

        console.log(bestand);
        if (bestand !== null) {
            opslaanButton.innerText = "Uploaden...";
            opslaanButton.classList.add("disabled");

            const storageRef = firebase.storage().ref();
            const fileRef = storageRef.child(`banner/${bestand.name}`);
            const uploadTask = fileRef.put(bestand);
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            {
                'complete': finishedUploading
            }); 
        } else {
            settings.fileLink = "";

            await firebase.firestore().collection('settings').doc('banner').update(settings); 
            window.location.reload();
        }
    }

    var finishedUploading = async () => {
        const URL = await firebase.storage().ref().child(`banner/${bestand.name}`).getDownloadURL();        
        settings.fileLink = URL;

        await firebase.firestore().collection('settings').doc('banner').update(settings); 
        window.location.reload();
    }
    
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file !== null) console.log(file);
        setBestand(file);
    }

    return (
        <div>
            {!settings ? "Banner instellingen laden..." :
            <form onSubmit={handleSubmit} id="memberForm" style={{marginTop:"0"}}>
                <div className="input-field">
                    <label htmlFor="bannerTekst" className="grey-text text-darken-3 active">Banner Tekst</label>
                    <input type="text" id="bannerTekst" onChange={e => settings.bannerTekst = e.target.value} defaultValue={settings.bannerTekst} required />
                </div>
                <div className="input-field">
                    <label htmlFor="bannerTekst" className="grey-text text-darken-3 active">Knop Tekst</label>
                    <input type="text" id="bannerTekst" onChange={e => settings.knopTekst = e.target.value} defaultValue={settings.knopTekst} />
                </div>
                <input type="file" accept=".pdf" onChange={handleFileChange} />
                <p>
                    <label htmlFor="bannerActive" className="grey-text text-darken-3">
                        <input type="checkbox" id="bannerActive" onChange={e => settings.showBanner = e.target.checked} defaultChecked={settings.showBanner} />
                        <span>Laat banner zien</span>
                    </label>
                </p>
                <div className="input-field">
                    <button className="btn blue darken-4 z-depth-0" id="opslaanButton">Opslaan</button>
                </div>
            </form>}
        </div>
    )
}

export default BannerSettings;