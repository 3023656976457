import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Button, Table } from 'react-materialize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/app';

const db = firebase.firestore();

const GroepList = (props) => {  
    const { groepen } = props;

    const deleteGroep = async (groepId) => {
        const groep= await db.collection("groepen").doc(groepId).get();
        if(groep.data().fotoNaam){
            const groepRef = firebase.storage().ref().child(`groepen/${groep.data().fotoNaam}`);
            groepRef.delete().then(function() {       
                db.collection("groepen").doc(groepId).delete();
            }).catch(function(error) {
                console.log(error);
            }); 
        } else {
            db.collection("groepen").doc(groepId).delete();
        }      
    }

    return (
        <div>
            <Table className="responsive-table">
                <thead>
                    <tr>
                        <th data-field="naam">Naam</th>
                        <th data-field="functie">Leraar</th>
                        <th data-field="modify"></th>
                        <th data-field="delete"></th>
                    </tr>
                </thead>
                <tbody>
                    { groepen && groepen.map(groep => {
                        return(
                            <tr key = {groep.id}>
                                <td>{groep.naam}</td>
                                <td>{groep.leraar}</td>
                                <td>{/*<EditUser groep={groep} />*/}Edit</td>
                                <td><Button className="red accent-4" onClick={() => deleteGroep(groep.id)}><FontAwesomeIcon icon={faTrashAlt}/></Button></td>
                            </tr>                            
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        groepen: state.firestore.ordered.groepen
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'groepen', orderBy: ['naam'] }
    ])
)(GroepList);