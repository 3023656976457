import { Switch, Route } from 'react-router-dom';
import Home from './static/Home';
import School from './static/School';
import Missie from './static/Missie';
import NOB from './static/NOB';
import Bestuur from './static/Bestuur';
import Dashboard from './Dashboard/Dashboard';
import Team from './Team/Team';
import Vacatures from './Vacatures/Vacatures';
import VrijeDagen from './VrijeDagen/VrijeDagen';
import Kalender from './Kalender/Kalender';
import Nieuws from './Nieuws/Nieuws';
import Nieuwsbrieven from './Nieuwsbrieven/Nieuwsbrieven';
import FotoAlbums from './FotoAlbums/FotoAlbums';
import Account from './auth/Account';
import Groep from './Groepen/Groep';
import ShowFotoAlbum from './FotoAlbums/ShowFotoAlbum';

const Routes = () => {
    return (
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/school' component={School} />
            <Route path='/missie' component={Missie} />
            <Route path='/nob' component={NOB} />
            <Route path='/bestuur' component={Bestuur} />
            <Route path='/team' component={Team} />
            <Route path='/dashboard' component={Dashboard} />
            <Route path='/vacatures' component={Vacatures} />
            <Route path='/vrijedagen' component={VrijeDagen} />
            <Route path='/kalender' component={Kalender} />
            <Route path='/nieuws' component={Nieuws} />
            <Route path='/nieuwsbrieven' component={Nieuwsbrieven} />
            <Route exact path='/fotoalbums' component={FotoAlbums} />
            <Route path='/fotoalbums/show/:id' component={ShowFotoAlbum} />
            <Route path='/account' component={Account} />
            <Route path='/groepen/:id' component={Groep} />
        </Switch>
    );
}

export default Routes;