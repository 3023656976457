import React, {useState, useEffect}  from 'react';
import { Button, Modal } from 'react-materialize';
import firebase from 'firebase/app';

function Home() {
    const [settings, setSettings] = useState(null);
    const [contact, setContact] = useState(null);

    var knopTekst = "";

    const fetchSettings = async () => {
        const settingsDoc = await firebase.firestore().collection('settings').doc('teksten').get();
        setSettings(settingsDoc.data());
        
        const contactDoc = await firebase.firestore().collection('settings').doc('contact').get();
        setContact(contactDoc.data());
    }
    if(!settings){
        fetchSettings();
    } else{
        knopTekst = settings.welkomKnop;
    }
    
    useEffect(() => {
        document.title = `Het Kleurenorkest`;
    });

    return (
        <div className="container row" style={{overflow:"hidden", position:"relative", width:"100%"}}>
            <div className="col l6 left">
                <br/>
                <h4>Welkom!</h4>
                {!settings ? "Tekst laden..." :
                <p className="text-formatting">{`${settings.welkom}`}</p>}
                <br/>
                {!contact ? null :
                <div className="hide-on-large-only">
                    <Modal
                        actions={[
                            <Button flat modal="close" node="button" waves="green">Sluiten</Button>
                        ]}
                        header={`${knopTekst}`}
                        id="modal-kennis-small"
                        trigger={<Button className="btn-kennis light-green accent-3 black-text" node="button" style={{minWidth:"170px"}}><b>{`${knopTekst}`}</b></Button>}
                    >
                        {!settings ? "Tekst laden..." :
                        <p className="text-formatting">{`${settings.welkomPopup}`}</p>}
                        <br/>
                        
                        <h5>Contact Gegevens</h5>
                        <p>
                        Adres:		{`${contact.adres}`}<br/>
                        Plaats:		{`${contact.plaats}`}<br/>
                        Telefoon:	{`${contact.telefoon}`}<br/>
                        Emailadres: <a href={`mailto:${contact.email}`}>{`${contact.email}`}</a><br/></p>
                    </Modal>
                </div>}
            </div>
            <div className="col l6 welcome-img right valign-wrapper hide-on-med-and-down">
            <ul>
                <li><img className="responsive-img" src="/img/gebouw/gebouw_1.jpg" alt="schoolgebouw"/></li>
                {!contact ? null :
                <li><Modal
                    actions={[
                        <Button flat modal="close" node="button" waves="green">Sluiten</Button>
                    ]}
                    header={`${knopTekst}`}
                    id="modal-kennis"
                    trigger={<Button className="btn-kennis light-green accent-3 black-text" node="button"><b>{`${knopTekst}`}</b></Button>}
                >
                    {!settings ? "Tekst laden..." :
                    <p className="text-formatting">{`${settings.welkomPopup}`}</p>}
                    <br/>
                    
                    <h5>Contact Gegevens</h5>
                    <p>
                    Adres:		{`${contact.adres}`}<br/>
                    Plaats:		{`${contact.plaats}`}<br/>
                    Telefoon:	{`${contact.telefoon}`}<br/>
                    Emailadres: <a href={`mailto:${contact.email}`}>{`${contact.email}`}</a><br/></p>
                </Modal></li>}
            </ul>
                
                
            </div>    
        </div>
    )
}

export default Home;