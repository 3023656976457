const initState = {
    groepsBerichten: [
        {id: '1', titel: 'GroepsBericht', foto: '', fotoName: '', tekst: 'GroepsBericht Tekst', createdAt: new Date()}
    ]
}

const groepsBerichtReducer = (state = initState, action) => {
    switch (action.type){
        case 'ADD_GROEPSBERICHT':
            console.log('added GroepsBericht', action.nieuws);
            return state;
        case 'ADD_GROEPSBERICHT_ERROR':
            console.log('adding GroepsBericht error', action.err);
            return state;
        default:
            return state;
    }
}

export default groepsBerichtReducer;