import React from 'react';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Button, Modal } from 'react-materialize';
import M from "materialize-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';

const db = firebase.firestore();

const EditNieuws = ({nieuws}) => {
    const editNieuws = {
        id: nieuws.id,
        titelEdit: nieuws.titel,
        fotoEdit: nieuws.foto,
        fotoNameEdit: nieuws.fotoName,
        tekstEdit: nieuws.tekst,
        imageFile: null
    }
    
    const handleFileChange = async (e) => {
        if(e.target.files[0]){
            const image = e.target.files[0];
            editNieuws.imageFile = image;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        editNieuwsFunc(editNieuws);
    }

    const editNieuwsFunc = (editedNieuws) => {
        var saveButton = document.getElementById(`saveButton-${editedNieuws.id}`);
        saveButton.value = "Opslaan...";
        saveButton.classList.add("disabled");
        var modalElem = document.getElementById(`bewerk-modal-${editedNieuws.id}`)
        var instance = M.Modal.getInstance(modalElem);

        if(editedNieuws.imageFile){
            const uploadTask = firebase.storage().ref(`nieuws/${editedNieuws.imageFile.name}`).put(editedNieuws.imageFile);
            uploadTask.on('state_changed', 
            (snapshot) => {}, 
            (error) => { console.log(error); }, 
            () => {
                firebase.storage().ref('nieuws').child(editedNieuws.imageFile.name).getDownloadURL().then((fotoUrl) => {
                    if(editedNieuws.fotoNameEdit){
                        const nieuwsFotoRef = firebase.storage().ref().child(`nieuws/${editedNieuws.fotoNameEdit}`);
                        nieuwsFotoRef.delete().catch(function(error) {
                            console.log(error);
                        });
                    }
                    editedNieuws.fotoEdit = fotoUrl;
                    editedNieuws.fotoNameEdit = editedNieuws.imageFile.name;
                    db.collection("nieuws").doc(editedNieuws.id).update({
                        titel: editedNieuws.titelEdit,
                        foto: editedNieuws.fotoEdit,
                        fotoName: editedNieuws.fotoNameEdit,
                        tekst: editedNieuws.tekstEdit
                    }).then(() => {
                        saveButton.value = "Verandering opslaan";
                        saveButton.classList.remove("disabled");
                        instance.close();
                        resetForm();
                    }).catch((err) => {
                        console.log(err.message);
                    });                
                });
            });
        } else {
            db.collection("nieuws").doc(editedNieuws.id).update({
                titel: editedNieuws.titelEdit,
                tekst: editedNieuws.tekstEdit
            }).then(() => {
                saveButton.value = "Verandering opslaan";
                saveButton.classList.remove("disabled");
                instance.close();
                resetForm();
            }).catch((err) => {
                console.log(err.message);
            });
        }        
    }

    const resetForm = () => {
        document.getElementById(`editNieuwsForm-${editNieuws.id}`).reset();
        document.getElementById(`titelEditLabel-${editNieuws.id}`).classList.add("active");
        document.getElementById(`tekstEditLabel-${editNieuws.id}`).classList.add("active");
        editNieuws.imageFile = null;
    }
    
    return (
        <Modal
            actions={[<Button flat modal="close" node="button">Annuleer</Button>]}
            header={`Bewerk nieuws: ${nieuws.titel}`}
            id={`bewerk-modal-${editNieuws.id}`}
            open={false}
            trigger={<Button className="flat orange darken-2 z-depth-0"><FontAwesomeIcon icon={faUserEdit}/></Button>}
        >
            <form onSubmit={handleSubmit} id={`editNieuwsForm-${editNieuws.id}`} style={{marginTop:"0"}}>
                <div className="input-field">
                    <label htmlFor={`titelEdit-${editNieuws.id}`} id={`titelEditLabel-${editNieuws.id}`} className="grey-text text-darken-3 active">Titel</label>
                    <input type="text" id={`titelEdit-${editNieuws.id}`} onChange={e => editNieuws.titelEdit = e.target.value} defaultValue={editNieuws.titelEdit} required />
                </div>
                <div className="file-field input-field">
                    <div className="btn blue darken-2 z-depth-0" id="fotoButton">
                        <span htmlFor="fotoNieuwsEdit">Foto</span>
                        <input type="file" id="fotoNieuwsEdit" accept="image/*" onChange={handleFileChange} />
                    </div>
                    <div className="file-path-wrapper">
                        <input className="file-path" type="text" defaultValue={editNieuws.fotoNameEdit}/>
                    </div>
                </div>
                <div className="input-field">
                    <label htmlFor={`tekstEdit-${editNieuws.id}`} id={`tekstEditLabel-${editNieuws.id}`} className="grey-text text-darken-3 active">Tekst</label>
                    <textarea id={`tekstEdit-${editNieuws.id}`} className="materialize-textarea" onChange={e => editNieuws.tekstEdit = e.target.value} defaultValue={editNieuws.tekstEdit} />
                </div>
                <div className="input-field">
                    <input type="submit" value="Veranderingen opslaan" className="btn blue darken-4 z-depth-0" id={`saveButton-${editNieuws.id}`} />
                    <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                </div>                
            </form>
        </Modal>
    )
}

export default EditNieuws;