import React from 'react';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Button, Modal } from 'react-materialize';
import M from "materialize-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import { DatePicker } from 'react-materialize';

const datePickerOptions = {
    autoClose: true,
    firstDay: 1,
    format: 'dd mmm yyyy',
    i18n: {
        cancel: 'Cancel',
        clear: 'Clear',
        done: 'Ok',
        months: [
            'Januari',
            'Februari',
            'Maart',
            'April',
            'Mei',
            'Juni',
            'Juli',
            'Augustus',
            'September',
            'Oktober',
            'November',
            'December'
        ],
        monthsShort: [
            'Jan',
            'Feb',
            'Maa',
            'Apr',
            'Mei',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Okt',
            'Nov',
            'Dec'
        ],
        nextMonth: '›',
        previousMonth: '‹',
        weekdays: [
            'Zondag',
            'Maandag',
            'Dinsdag',
            'Woensdag',
            'Donderdag',
            'Vrijdag',
            'Zaterdag'
        ],
        weekdaysAbbrev: [
            'Zo',
            'Ma',
            'Di',
            'Wo',
            'Do',
            'Vr',
            'Za'
        ],
        weekdaysShort: [
            'Zon',
            'Maa',
            'Din',
            'Woe',
            'Don',
            'Vrij',
            'Zat'
        ]
    },
    minDate: new Date()
}

const db = firebase.firestore();

const EditVacature = ({vacature}) => {
    const editVacature = {
        id: vacature.id,
        titelEdit: vacature.titel,
        tekstEdit: vacature.tekst,
        einddatumEdit: vacature.einddatum
    }
    const handleChange = (e) => {
        var cutId = e.target.id.length - (vacature.id.length+1);
        var targetId = e.target.id.substring(0,cutId);
        editVacature[targetId] = e.target.value;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        editVacatureFunc(editVacature);
    }
    const editVacatureFunc = (editedVacature) => {
        var saveButton = document.getElementById(`saveButton-${editedVacature.id}`);
        saveButton.value = "Opslaan...";
        saveButton.classList.add("disabled");
        var modalElem = document.getElementById(`bewerk-modal-${editedVacature.id}`)
        var instance = M.Modal.getInstance(modalElem);
        db.collection("vacatures").doc(editedVacature.id).update({
            titel: editVacature.titelEdit,
            tekst: editVacature.tekstEdit,
            einddatum: editVacature.einddatumEdit
        }).then(() => {
            saveButton.value = "Verandering opslaan";
            saveButton.classList.remove("disabled");
            instance.close();
            resetForm();
        }).catch((err) => {
            console.log(err.message);
        });
    }

    const resetForm = () => {
        document.getElementById(`editVacatureForm-${editVacature.id}`).reset();
        document.getElementById(`titelEditLabel-${editVacature.id}`).classList.add("active");
        document.getElementById(`tekstEditLabel-${editVacature.id}`).classList.add("active");
        document.getElementById(`einddatumEditLabel-${editVacature.id}`).classList.add("active");
    }

    const handleDateChange = (e) => {
        const date = new Date(e);
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        editVacature.einddatumEdit = date;
    }
    
    return (
        <div>
            <Modal
                actions={[<Button flat modal="close" node="button">Annuleer</Button>]}
                header="Bewerk vacature"
                id={`bewerk-modal-${editVacature.id}`}
                open={false}
                trigger={<Button className="flat orange darken-2"><FontAwesomeIcon icon={faUserEdit}/></Button>}
            >
                <form onSubmit={handleSubmit} id={`editVacatureForm-${editVacature.id}`} style={{marginTop:"0"}}>
                    <div className="input-field">
                        <label htmlFor={`titelEdit-${editVacature.id}`} id={`titelEditLabel-${editVacature.id}`} className="grey-text text-darken-3 active">Titel <sup className="red-text bold-text">*</sup></label>
                        <input type="text" id={`titelEdit-${editVacature.id}`} onChange={handleChange} defaultValue={editVacature.titelEdit} required />
                    </div>
                    <div className="input-field">
                        <label htmlFor={`tekstEdit-${editVacature.id}`} id={`tekstEditLabel-${editVacature.id}`} className="grey-text text-darken-3 active">Tekst <sup className="red-text bold-text">*</sup></label>
                        <textarea id={`tekstEdit-${editVacature.id}`} className="materialize-textarea" defaultValue={editVacature.tekstEdit} onChange={handleChange} required />
                    </div>
                    <div className="input-field">
                        <label htmlFor={`einddatumEdit-${editVacature.id}`} id={`einddatumEditLabel-${editVacature.id}`} className="grey-text text-darken-3 active">Einddatum <sup className="red-text bold-text">*</sup></label>
                        <DatePicker 
                            id={`einddatumEdit-${editVacature.id}`}
                            options={datePickerOptions} 
                            onChange={handleDateChange}
                            defaultValue={DateTime.fromJSDate(editVacature.einddatumEdit.toDate()).toLocaleString()}
                            required
                        />
                    </div>
                    <div className="input-field">
                        <input type="submit" value="Veranderingen opslaan" className="btn blue darken-4 z-depth-0" id={`saveButton-${editVacature.id}`} />
                        <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                    </div>                
                </form>
            </Modal>
        </div>
    )
}

export default EditVacature;