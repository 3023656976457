import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { MediaBox } from 'react-materialize';
import firebase from '../../config/fbConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const db = firebase.firestore();

function HeaderPhotos() {
    const [albumData, setAlbumData] = useState(null);
    const [fotos, setFotos] = useState([]);
    
    const uploadButton = document.getElementById("saveButton");

    const fetchAlbum = async () => {
        const albumDoc = await db.collection("fileLinks").doc("headerPhotos").get();
        setAlbumData(albumDoc.data());
    }
    if(!albumData){
        fetchAlbum();
    }

    const deleteFoto = (fotoName, fotoUrl) => {
        const album = db.collection("fileLinks").doc("headerPhotos");
        album.update({ 
            photoUrls: firebase.firestore.FieldValue.arrayRemove({
                name: fotoName,
                url: fotoUrl
            })
        }).then(() => {
            const fotoRef = firebase.storage().ref().child(`header/${fotoName}`);
            fotoRef.delete();
        });      
    }

    const handleFileChange = (e) => {
        if(e.target.files[0]){
            setFotos([...e.target.files]);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        uploadButton.innerText = "Uploaden...";
        uploadButton.classList.add("disabled");
        addFotos();
    }

    const addFotos = async () => {
        console.log("addFotos");

        const storageRef = firebase.storage().ref();
        const fileUploads = fotos.map(file => storageRef.child(`header/${file.name}`).put(file));
        
        const files = (await Promise.all(fileUploads));
        const fileNames = files.map(f => f._delegate.metadata.name);
        const fileURLReqs = files.map(f => f.ref.getDownloadURL());
        const fileUrls = await Promise.all(fileURLReqs);

        const fileObjects = fileNames.map((f,i) => ({
            name: f,
            url: fileUrls[i]
        }));

        const album = db.collection("fileLinks").doc("headerPhotos");
        album.update({ 
            photoUrls: firebase.firestore.FieldValue.arrayUnion(...fileObjects)
        });

        resetForm();
        uploadButton.innerText = "Foto's Uploaden";
        uploadButton.classList.remove("disabled");
    }

    const resetForm = () => {
        document.getElementById(`addFotosForm`).reset();
        setFotos(null);
    }

    useEffect(() => {
        if(uploadButton !== null) {
            if(fotos !== null) {
                uploadButton.classList.remove("disabled");
            } else {
                uploadButton.classList.add("disabled");
            }
        }
    })

    return (
        <div className="container" style={{overflow:"hidden", position:"relative"}}>
            {!albumData ? "Foto album laden..." :
            <div>
                <br/>
                <div>
                    <form onSubmit={handleSubmit} id={`addFotosForm`} style={{marginTop:"0"}}>
                        <div className="file-field input-field">
                            <div className="btn blue darken-2 z-depth-0" id="fotoButton">
                                <span htmlFor="fotoGroepEdit">Foto's</span>
                                <input type="file" id="fotoGroepEdit" accept="image/*" multiple onChange={handleFileChange} />
                            </div>
                            <div className="file-path-wrapper">
                                <input className="file-path" type="text" />
                            </div>
                        </div>
                        <div className="input-field">
                            <input type="submit" value="Foto's Uploaden" className="btn blue darken-4 z-depth-0 disabled" id="saveButton" />
                            <button type="button" className="btn light-blue lighten-2 z-depth-0 right" onClick={resetForm}>Reset formulier</button>
                        </div>                
                    </form>
                </div>
                <hr />
                <div className="row">
                    {albumData.photoUrls && albumData.photoUrls.map(foto => {
                        return (
                            <div className="card col s12 m6 l4 orange z-depth-0" style={{padding:"0.5rem", margin:0}}>
                                <MediaBox id="team_foto">
                                    <img src={foto.url} alt="Foto" width="100%" />
                                </MediaBox> 
                                <button className="btn z-depth-0 red accent-4" id={`deleteButton_${albumData.id}`} onClick={() => {deleteFoto(foto.name, foto.url)}} style={{marginLeft:20}}><FontAwesomeIcon icon={faTrashAlt}/></button>
                            </div>
                        )
                    })}
                </div>
            </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(HeaderPhotos);