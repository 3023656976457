import firebase from 'firebase/app';

export const addGroepsBericht = (bericht) => {
    return (dispatch, { getFirebase, getFirestore }) => {
        const firestore = firebase.firestore();
        firestore.collection('groepsBerichten').doc(bericht.id).set({
            ...bericht
        }).then(() => {
            dispatch({type: 'ADD_GROEPSBERICHT', bericht});
        }).catch((err) => {
            dispatch({type: 'ADD_GROEPSBERICHT_ERROR', err});
        });        
    }
}