import React from 'react';
import { DateTime } from 'luxon';
import { MediaBox } from 'react-materialize';
import firebase from 'firebase/app';
import EditGroepsBericht from './EditGroepsBericht';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

const db = firebase.firestore();

function GroepsBericht(props){
    const { bericht, groepId, profile } = props; 

    const deleteBericht = () => {
        if(bericht.fotoName){
            const berichtRef = firebase.storage().ref().child(`groepen/berichten/${bericht.fotoName}`);
            berichtRef.delete().then(function() {
                var groep = db.collection("groepen").doc(groepId);
                groep.update({
                    berichten: firebase.firestore.FieldValue.arrayRemove(bericht)
                });
            }).catch(function(error) {
                console.log(error);
            }); 
        } else {
            var groep = db.collection("groepen").doc(groepId);
            groep.update({
                berichten: firebase.firestore.FieldValue.arrayRemove(bericht)
            });
        }      
    }

    const ToggleReadMore = () => {
        document.getElementById(`nieuwsTekst_${bericht.id}`).classList.toggle("line-clamp");
        var b = document.getElementById(`readMoreButton_${bericht.id}`);
        b.innerHTML = b.innerHTML === "Lees Meer" ? "Lees Minder" : "Lees Meer";
    }

    return(
        <div className="col s12 l6">
            {!bericht ? "Bericht laden..." :
            <div className="card deep-orange lighten-4">
                {bericht.fotoUrl ?
                    <div style={{padding:"1rem"}}>
                        <MediaBox>
                            <img src={bericht.fotoUrl} alt="Nieuws foto" width="100%" />
                        </MediaBox>
                    </div>
                : null}            
                <div className="card-content">
                    <span className="card-title"><b>{bericht.titel}</b></span>
                    <p className="grey-text">{DateTime.fromJSDate(bericht.createdAt.toDate()).toLocaleString(DateTime.DATETIME_MED)}</p>
                    <p className="line-clamp text-formatting" id={`nieuwsTekst_${bericht.id}`}>{`${bericht.tekst}`}</p>
                </div>
                <div className="card-action">
                    <button className="btn z-depth-0" id={`readMoreButton_${bericht.id}`} onClick={ToggleReadMore}>Lees Meer</button>
                    {profile && profile.role === 'admin' ? 
                        <div className="right">
                            <EditGroepsBericht bericht={bericht} groepId={groepId} />
                            <button className="btn z-depth-0 red accent-4" id={`deleteButton_${bericht.id}`} onClick={deleteBericht} style={{marginLeft:20}}><FontAwesomeIcon icon={faTrashAlt}/></button> 
                        </div>
                    : null}
                </div>
            </div>}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(GroepsBericht);