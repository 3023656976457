import React, {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import SignUp from './SignUp';
import UserList from './UserList';
import { Collapsible, CollapsibleItem, Tabs, Tab } from 'react-materialize';
import CreateTeamMember from './CreateTeamMember';
import TeamListDashboard from './TeamListDashboard';
import ChangeTeamPhoto from './ChangeTeamPhoto';
import CreateVacature from './CreateVacature';
import VacatureList from './VacatureList';
import ChangeSchoolgids from './ChangeSchoolgids';
import CreateGroep from './CreateGroep';
import GroepList from './GroepList';
import BannerSettings from './BannerSettings';
import EditStaticTexts from './EditStaticTexts';
import ChangeKalender from './ChangeKalender';
import ContactSettings from './ContactSettings';
import HeaderPhotos from './HeaderPhotos';
import ChangeBackground from './ChangeBackground';

function Dashboard (props) {
    const {auth, profile } = props;
    
    useEffect(() => {
        document.title = `Dashboard`;
    });

    if( !auth.uid || profile.role !== 'admin') return <Redirect to='/' />

    return (
        <div className="container">
        <br />
            <h4><b>Dashboard</b></h4>
            <hr />
            <Tabs
                className="tab-demo tabs-fixed-width"
                scope="tabs-22"
            >
                <Tab
                    className="orange lighten-3"
                    options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                    }}
                    title="Contact"
                >
                    <div>
                        <ContactSettings />
                    </div>
                </Tab>
                <Tab
                    className="orange lighten-3"
                    options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                    }}
                    title="Gebruikers"
                >
                    <div>
                        <Collapsible accordion>
                            <CollapsibleItem
                                expanded={false}
                                header={<div><b>Nieuwe gebruiker toevoegen</b></div>}
                                node="div"
                                className="amber accent-1"
                            >
                                <SignUp /> 
                            </CollapsibleItem>
                        </Collapsible>
                        <UserList/>
                    </div>
                </Tab>
                <Tab
                    className="orange lighten-3"
                    options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                    }}
                    title="Team"
                >
                    <div>
                        <Collapsible accordion>
                            <CollapsibleItem
                                expanded={false}
                                header={<div><b>Teamfoto vervangen</b></div>}
                                node="div"
                                className="amber accent-1"
                            >
                                <ChangeTeamPhoto/>
                            </CollapsibleItem>
                            <CollapsibleItem
                                expanded={false}
                                header={<div><b>Nieuw teamlid toevoegen</b></div>}
                                node="div"
                                className="amber accent-1"
                            >
                                <CreateTeamMember/>
                            </CollapsibleItem>
                        </Collapsible>
                        <TeamListDashboard/>
                    </div>
                </Tab>
                <Tab
                    className="orange lighten-3"
                    options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                    }}
                    title="Groepen"
                >
                    <div>
                        <Collapsible accordion>
                            <CollapsibleItem
                                expanded={false}
                                header={<div><b>Nieuwe groep toevoegen</b></div>}
                                node="div"
                                className="amber accent-1"
                            >
                                <CreateGroep/>
                            </CollapsibleItem>
                        </Collapsible>
                        <GroepList/>
                    </div>
                </Tab>
                <Tab
                    className="orange lighten-3"
                    options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                    }}
                    title="Bestanden"
                >
                    <div>
                        <Collapsible accordion>
                            <CollapsibleItem
                                expanded={false}
                                header={<div><b>Schoolgids vervangen</b></div>}
                                node="div"
                                className="amber accent-1"
                            >
                                <ChangeSchoolgids/>
                            </CollapsibleItem>
                        </Collapsible>
                        <Collapsible accordion>
                            <CollapsibleItem
                                expanded={false}
                                header={<div><b>Kalender vervangen</b></div>}
                                node="div"
                                className="amber accent-1"
                            >
                                <ChangeKalender/>
                            </CollapsibleItem>
                        </Collapsible>
                    </div>
                </Tab>
                {/* <Tab
                    className="orange lighten-3"
                    options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                    }}
                    title="Kalender"
                >
                    <div>
                        <h6><strong><u>Vrije dagen / Vakanties</u></strong></h6>
                        <Collapsible accordion>
                            <CollapsibleItem
                                expanded={false}
                                header={<div><b>Nieuwe vrije dag/vakantie toevoegen</b></div>}
                                node="div"
                                className="amber accent-1"
                            >
                                <CreateVrijeDag/>
                            </CollapsibleItem>
                        </Collapsible> 
                        <VrijeDagenListDashboard/>
                        <h6 style={{marginTop: "3rem"}}><strong><u>Evenementen</u></strong></h6>
                        <Collapsible accordion>
                            <CollapsibleItem
                                expanded={false}
                                header={<div><b>Nieuw evenement toevoegen</b></div>}
                                node="div"
                                className="amber accent-1"
                            >
                                <CreateEvenement/>
                            </CollapsibleItem>
                        </Collapsible> 
                        <Evenementen/>
                    </div>
                </Tab> */}
                <Tab
                    className="orange lighten-3"
                    options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                    }}
                    title="Vacatures"
                >
                    <div>
                        <Collapsible accordion>
                            <CollapsibleItem
                                expanded={false}
                                header={<div><b>Nieuwe vacature toevoegen</b></div>}
                                node="div"
                                className="amber accent-1"
                            >
                                <CreateVacature/>
                            </CollapsibleItem>
                        </Collapsible>
                        <VacatureList/>
                    </div>
                </Tab>
                <Tab
                    className="orange lighten-3"
                    options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                    }}
                    title="Teksten"
                >
                    <div>
                        <EditStaticTexts />
                    </div>
                </Tab>
                <Tab
                    className="orange lighten-3"
                    options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                    }}
                    title="Banner"
                >
                    <div>
                        <BannerSettings />
                    </div>
                </Tab>
                <Tab
                    className="orange lighten-3"
                    options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                    }}
                    title="Header"
                >
                    <div>
                        <Collapsible accordion>
                        <CollapsibleItem
                                expanded={false}
                                header={<div><b>Achtergrondfoto vervangen</b></div>}
                                node="div"
                                className="amber accent-1"
                            >
                                <ChangeBackground/>
                            </CollapsibleItem>
                            <CollapsibleItem
                                expanded={false}
                                header={<div><b>Cirkel Foto's</b></div>}
                                node="div"
                                className="amber accent-1"
                            >
                                <HeaderPhotos />
                            </CollapsibleItem>
                        </Collapsible>                        
                    </div>
                </Tab>
            </Tabs>                      
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Dashboard);